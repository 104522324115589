// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Design-module__design--wkrmB {\n  background-color: white;\n  border: 1px solid #dddddd;\n  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.07);\n  position: relative;\n}\n\n.Design-module__elementsContainer--1BomU {\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.Design-module__selected--2e-Ly {\n  pointer-events: none;\n  position: absolute;\n  z-index: 99;\n  border: 2px solid aqua;\n}\n", ""]);
// Exports
exports.locals = {
	"design": "Design-module__design--wkrmB",
	"elementsContainer": "Design-module__elementsContainer--1BomU",
	"selected": "Design-module__selected--2e-Ly"
};
module.exports = exports;
