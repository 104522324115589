// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ZoomBar-module__zoomBar--ptijk {\n  position: absolute;\n  bottom: 25px;\n  right: calc(50% - 170px);\n  transform: translate(-50%, 0);\n  padding: 12px 16px;\n  display: flex;\n  background-color: #ffffff;\n  border-radius: 14px;\n  width: 170px;\n  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.15);\n  box-sizing: border-box;\n  z-index: 9;\n}\n\n.ZoomBar-module__buttonBlock--2oGxf {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  user-select: none;\n  width: 100%;\n}\n\n.ZoomBar-module__buttonZoom--2KVuU {\n  width: 25px;\n  height: 25px;\n  cursor: pointer;\n  transition: 0.3s;\n}\n\n.ZoomBar-module__buttonZoom--2KVuU.ZoomBar-module__disabledBtn--X3VU0 {\n  opacity: 0.4;\n  cursor: not-allowed;\n}\n\n.ZoomBar-module__zoomValue--3XLyS {\n  font-size: 18px;\n  font-weight: 700;\n}\n", ""]);
// Exports
exports.locals = {
	"zoomBar": "ZoomBar-module__zoomBar--ptijk",
	"buttonBlock": "ZoomBar-module__buttonBlock--2oGxf",
	"buttonZoom": "ZoomBar-module__buttonZoom--2KVuU",
	"disabledBtn": "ZoomBar-module__disabledBtn--X3VU0",
	"zoomValue": "ZoomBar-module__zoomValue--3XLyS"
};
module.exports = exports;
