// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Browse-module__container--Lvjii {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  transition: all 0.3s;\n}\n\n.Browse-module__blurred--3qyoT {\n  filter: blur(4px);\n}\n\n.Browse-module__container-wide--1nMNd {\n  padding: 0;\n  margin: 0 auto;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Browse-module__container--Lvjii",
	"blurred": "Browse-module__blurred--3qyoT",
	"container-wide": "Browse-module__container-wide--1nMNd"
};
module.exports = exports;
