import React from 'react'
import SVG from 'react-inlinesvg'
import { getShapes } from '../../../utils/shapes.constants'
import styles from './ElementsDrawer.module.css'
import scrollbar from '../scrollbar.module.css'
import Shape from './Shape'

function ElementsDrawer({ addElement }) {
  const mockupResponse = getShapes()

  return (
    <>
      {/* <DrawerSearch placeholder="Search icons and shapes" /> */}
      <div className={scrollbar.customScroll}>
        <div className={styles.elementsDrawer}>
          <div className={styles.itemList}>
            {mockupResponse.map(item => (
              <Shape item={item} key={item.id} addElement={addElement} image={item.image} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ElementsDrawer
