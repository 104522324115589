import * as React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import styles from './addBrand.module.css'
import { useState, useRef } from 'react'
import { NoImage } from '../../../../assets/svg'
import SVG from 'react-inlinesvg'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import ColorPic from './ColorPic'
import { FileDrop } from 'react-file-drop'
import { connect } from 'react-redux'
import { createBrand, editBrand } from '../../../../actions/brands_actions'
import FontDemo from './FontDemo'
import FontPicker from './FontPicker'
import { useTranslation } from 'react-i18next'

function AddBrand(props) {
  const { t } = useTranslation()
  const { setOpenedProps, userId, brand, CreateBrand, EditBrand } = props
  const defaultData = {
    name: '',
    images: { img: '', imgSrc: '' },
    colors: '#000000',
    recentColors: ['red', 'green', 'blue'],
    font1: {
      family: 'Proxima Nova',
      fontSize: 25,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none'
    },
    font2: {
      family: 'Arial',
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none'
    },
    font3: {
      family: 'Times New Roman',
      fontSize: 10,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none'
    }
  }

  const [data, setData] = useState(Object.keys(brand).length > 0 ? {...brand, 'recentColors': ['red', 'green', 'blue']} : defaultData)
  const [opened, setOpened] = useState(false)

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleDrop = files => {
    let file = files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const logo = {
        img: file,
        imgSrc: reader.result.toString()
      }
      setData({...data, images: logo })
    }
  }

  const handleCreate = () => {
    const Data = {
      creatorId: userId,
      name: data.name,
      images: data.images,
      colors: data.colors,
      font1: data.font1,
      font2: data.font2,
      font3: data.font3
    }
    CreateBrand(Data)
    setOpenedProps(false)
  }

  const handleEdit = () => {
    EditBrand(data)
    setOpenedProps(false)
  }

  const setColor = color => {
    setData({ ...data, colors: color })
  }

  const setFontOnElement = (element, font) => {
    setData({ ...data, [element]: font })
  }

  const fileInputRef = useRef(null)

  const onFileInputChange = event => {
    const { files } = event.target
    handleDrop(files)
  }

  return (
    <Dialog open={true}>
      <DialogTitle>
        <p>
          <b>{t('addBrand')}</b>
        </p>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <input
              style={{ backgroundColor: '#efefef', padding: '0.5rem', borderRadius: '15px', marginBottom: '10px' }}
              type="text"
              name="name"
              placeholder={t('brandName')}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderWidth: '1px',
                borderRadius: '15px',
                '.file-drop > .file-drop-target.file-drop-dragging-over-target ': {
                  backgroundColor: 'silver'
                }
              }}
            >
              <FileDrop onDrop={handleDrop}>
                <CardHeader title={t('brandLogo')} titleTypographyProps={{ align: 'left', sx: { fontSize: 12 } }} />
                <CardContent>
                  <div className={styles.cardsContainer}>
                    {data.images.imgSrc ? (
                      <img
                        src={data.images.imgSrc}
                        style={{
                          borderRadius: '15px',
                          width: '50px',
                          height: '50px'
                        }}
                      />
                    ) : (
                      <SVG src={NoImage} style={{ width: '50px', height: '50px', borderRadius: '15px' }} />
                    )}

                    <button className={styles.btn} role="button" onClick={() => fileInputRef.current.click()}>
                      {t('addLogo')}
                    </button>
                    <input
                      style={{ display: 'none' }}
                      onChange={onFileInputChange}
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      accept="image/*"
                    />
                  </div>
                </CardContent>
              </FileDrop>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderWidth: '1px',
                borderRadius: '15px'
              }}
            >
              <CardHeader title={t('brandColors')} titleTypographyProps={{ align: 'left', sx: { fontSize: 12 } }} />
              <CardContent>
                <div className={styles.cardsContainer}>
                  <div
                    style={{ borderRadius: '15px', width: '50px', height: '50px', backgroundColor: data.colors }}
                  ></div>

                  <button className={styles.btn} role="button" onClick={() => setOpened(state => !state)}>
                    {t('addBrandColor')}
                  </button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderWidth: '1px',
                borderRadius: '15px'
              }}
            >
              <CardHeader title={t('fonts')} titleTypographyProps={{ align: 'left', sx: { fontSize: 12 } }} />

              <CardContent>
                <FontDemo title={t('header')} type="font1" action={setFontOnElement} font={data.font1} />

                <FontDemo title={t('subHeader')} type="font2" action={setFontOnElement} font={data.font2} />

                <FontDemo title={t('body')} type="font3" action={setFontOnElement} font={data.font3} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <div className={styles.cardsContainer}>
              <button className={styles.btnCancel} role="button" onClick={() => setOpenedProps(false)}>
                {t('cancel')}
              </button>
              {Object.keys(brand).length > 0 &&
                <button
                  className={styles.btnCreate}
                  role="button"
                  onClick={() => handleEdit()}
                >
                  {t('edit')}
                </button>
              }
              {Object.keys(brand).length < 1 &&
                <button
                  className={styles.btnCreate}
                  role="button"
                  onClick={() => handleCreate()}
                >
                  {t('create')}
                </button>
              }
            </div>
          </Grid>
        </Grid>
        {opened ? (
          <ColorPic
            setOpened={setOpened}
            colorProp={data.color}
            setColorProp={setColor}
            recentColors={data.recentColors}
          ></ColorPic>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.entities.users[state.session.id],
    ownProps,
    userId: state.session.id
  }
}

const mapDispatchToProps = dispatch => ({
  CreateBrand: brand => dispatch(createBrand(brand)),
  EditBrand: brand => dispatch(editBrand(brand))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand)
