import React from 'react'
import { Link } from 'react-router-dom'
import { FiChevronsLeft } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

const EmailSignup = ({ handleChange, handleSubmit, errors, changeView, animate }) => {
  const { t } = useTranslation()
  return (
    <form className={animate ? 'animated auth-form' : 'auth-form'} onSubmit={handleSubmit}>
      <div className="form-container">
        <div className="return-login">
          <button type="button" className="btn-none flex-center" onClick={changeView}>
            <FiChevronsLeft />
          </button>
          <h2>{t('createYourAccount')}</h2>
        </div>
        <p>
          {t('we')}&apos;{t('time')}
        </p>

        {errors.length ? <div className="error">{errors.join('. ')}</div> : ''}

        <input type="text" name="username" placeholder={t('name')} onChange={handleChange('username')} />
        <input type="text" name="email" placeholder={t('email')} onChange={handleChange('email')} />
        <input type="password" name="password" placeholder={t('password')} onChange={handleChange('password')} />
        <small>{t('use6OrMoreCharacters')}</small>
        <button type="submit" className="btn-blue">
          {t('getStarted,It')}&apos;{t('sfree')}
        </button>
        <small>
          {t('bySigningUp,YouAgreeToGwaphics')}&apos;&nbsp;
          <u>{t('termsOfUse')}</u>
          &nbsp;{t('and')}&nbsp;
          <u>{t('privacyPolicy')}</u>.
        </small>
        <small>
          {t('alreadySignedUp')}&nbsp;
          <Link to="/login">{t('logIn')}</Link>
        </small>
      </div>
    </form>
  )
}

export default EmailSignup
