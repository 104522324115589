// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Editor-module__editorContainer--bKsoK {\n  background-color: #edf0f2;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n}\n\n.Editor-module__editorBottomContainer--2KMW2 {\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n  position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"editorContainer": "Editor-module__editorContainer--bKsoK",
	"editorBottomContainer": "Editor-module__editorBottomContainer--2KMW2"
};
module.exports = exports;
