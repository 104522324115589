// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ElementsDrawer-module__elementsDrawer--30shq {\n  width: 312px;\n  display: flex;\n  flex-direction: column;\n  padding-left: 16px;\n  padding-right: 24px;\n}\n\n.ElementsDrawer-module__itemList--2dFKC {\n  margin-top: 16px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.ElementsDrawer-module__item--288fC {\n  width: 100px;\n  height: 100px;\n  margin-bottom: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.ElementsDrawer-module__item--288fC > svg {\n  fill: #c7d0d8;\n  width: 100px;\n  height: 100px;\n}\n\n@media only screen and (max-width: 991px) {\n  .ElementsDrawer-module__elementsDrawer--30shq {\n    width: 240px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"elementsDrawer": "ElementsDrawer-module__elementsDrawer--30shq",
	"itemList": "ElementsDrawer-module__itemList--2dFKC",
	"item": "ElementsDrawer-module__item--288fC"
};
module.exports = exports;
