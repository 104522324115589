import { useTranslation } from 'react-i18next'

const tiers = [
  {
    title: 'free',
    subheader: 'mostPopular',
    price: '0',

    description: [
      '10UsersIncluded',
      '2GBOfStorage',
      'helpCenterAccess',
      'emailSupport',
      '10UsersIncluded',
      '2GBOfStorage',
      'helpCenterAccess',
      'emailSupport'
    ],
    buttonText: 'signUpForFree',
    buttonVariant: 'outlined'
  },
  {
    title: 'pro',
    subheader: 'mostPopular',
    price: '25',
    oldPrice: '35',
    description: ['20UsersIncluded', '10GBOfStorage', 'helpCenterAccess', 'priorityEmailSupport'],

    buttonText: 'getStarted',
    buttonVariant: 'contained'
  },
  {
    title: 'enterprise',
    subheader: 'mostPopular',
    price: '45',
    oldPrice: '50',
    description: ['50UsersIncluded', '30GBOfStorage', 'helpCenterAccess', 'phone&EmailSupport'],
    buttonText: 'contactUs',
    buttonVariant: 'contained',
    optimalBadge: 'yes'
  },
  {
    title: 'enterprise',
    subheader: 'mostPopular',
    price: '75',
    oldPrice: '90',
    description: ['50UsersIncluded', '30GBOfStorage', 'helpCenterAccess', 'phone&EmailSupport'],
    buttonText: 'contactUs',
    buttonVariant: 'contained'
  }
]

export default tiers
