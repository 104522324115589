import React from 'react'
import styles from './TextDrawer.module.css'
import scrollbar from '../scrollbar.module.css'
import { useDrag } from 'react-dnd'
import TextItem from './TextItem'
import { useTranslation } from 'react-i18next'
const mockupResponse = [
  // need default width height and desc to search
  {
    id: 1,
    text: 'Add a heading',
    fontWeight: 800,
    fontSize: 28,
    fontFamily: 'Open Sans'
  },
  {
    id: 2,
    text: 'Add a subheading',
    fontWeight: 400,
    fontSize: 18,
    fontFamily: 'Open Sans'
  },
  {
    id: 3,
    text: 'Add a little bit of body text',
    fontWeight: 300,
    fontSize: 12,
    fontFamily: 'Open Sans'
  }
]

function TextDrawer({ addElement }) {
  const { t } = useTranslation()
  return (
    <>
      {/* <DrawerSearch placeholder="Search Text" /> */}
      <div className={scrollbar.customScroll}>
        <div className={styles.textDrawer}>
          <h3>{t('click')}</h3>
          <div className={styles.highlights}>
            <TextItem addElement={addElement} text={t('addAHeading')} content={mockupResponse[0]}></TextItem>

            <TextItem addElement={addElement} text={t('addASubheading')} content={mockupResponse[1]}></TextItem>
            <TextItem addElement={addElement} text={t('addABodyText')} content={mockupResponse[2]}></TextItem>
          </div>
          {/* <h3>Font Combinations</h3>
          <div className={styles.itemList}>
            {mockupResponse.map(item => (
              <div key={item.id} className={styles.item} onClick={() => AddElement(item)}>
                {item.text}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  )
}

export default TextDrawer
