// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NavBar-module__navBar--3pzEi {\n  padding: 6px 18px;\n  padding: 4px 18px;\n  font-size: 1.4rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.NavBar-module__nav--29zCn {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.NavBar-module__nav--29zCn > li {\n  padding: 4px 12px;\n  letter-spacing: 0.01rem;\n}\n\n/* .logo {\n  margin-left: 20px;\n} */\n\n.NavBar-module__logo--2SyGt > svg {\n  width: 50px;\n  height: 50px;\n  width: 42px;\n  height: 42px;\n}\n\n.NavBar-module__searchBar--GP78Y {\n  display: flex;\n  background-color: #efefef !important;\n  padding: 12px 25px;\n  border-radius: 50px;\n  margin: 0 25px;\n  width: 100%;\n}\n\n.NavBar-module__searchBar--GP78Y svg {\n  width: 17px;\n  height: 17px;\n  margin-right: 12px;\n}\n\n.NavBar-module__createDesignBtn--QK_5v {\n  width: 16rem !important;\n  border: 1px solid #3f51b5 !important;\n  background-color: #3f51b5 !important;\n  color: #fff !important;\n  text-transform: capitalize !important;\n  line-height: 16px !important;\n  border-radius: 50px !important;\n  padding: 5px 18px !important;\n  white-space: nowrap !important;\n}\n\n.NavBar-module__createDesignBtn--QK_5v span {\n  text-align: center !important;\n  font-size: 12px !important;\n}\n\n.NavBar-module__bellIcon--2piUw {\n  width: 4rem !important;\n  font-size: 2rem !important;\n}\n\n.NavBar-module__homeBtn--LH0TM {\n  width: 7rem !important;\n  justify-content: space-between !important;\n  align-items: center !important;\n  margin-left: 12px !important;\n}\n\n.NavBar-module__homeBtn--LH0TM svg {\n  font-size: 2rem !important;\n}\n\n.NavBar-module__homeBtn--LH0TM p {\n  font-weight: 600 !important;\n  margin-top: 6px !important;\n}\n[dir='rtl'] .NavBar-module__searchBar--GP78Y svg {\n  margin-left: 12px;\n  margin-right: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"navBar": "NavBar-module__navBar--3pzEi",
	"nav": "NavBar-module__nav--29zCn",
	"logo": "NavBar-module__logo--2SyGt",
	"searchBar": "NavBar-module__searchBar--GP78Y",
	"createDesignBtn": "NavBar-module__createDesignBtn--QK_5v",
	"bellIcon": "NavBar-module__bellIcon--2piUw",
	"homeBtn": "NavBar-module__homeBtn--LH0TM"
};
module.exports = exports;
