// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WorkArea-module__workArea--1rVnW {\n  background-color: #edf0f2;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n}\n\n.WorkArea-module__workContainer--1bLpd {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  position: relative;\n}\n\n.WorkArea-module__designContainer--3y8Nz {\n  padding: 50px;\n  margin: auto;\n}\n\n@media only screen and (max-width: 991px) {\n  .WorkArea-module__designContainer--3y8Nz {\n    padding: 15px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"workArea": "WorkArea-module__workArea--1rVnW",
	"workContainer": "WorkArea-module__workContainer--1bLpd",
	"designContainer": "WorkArea-module__designContainer--3y8Nz"
};
module.exports = exports;
