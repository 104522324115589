// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditablePanel-module__moveableEditable--b6WkQ {\n  position: absolute;\n  left: -40px;\n  top: 6px;\n  will-change: transform;\n  transform-origin: 0 0;\n}\n\n.EditablePanel-module__moveableButton--3sE_o {\n  min-width: 30px;\n  width: 30px;\n  height: 30px;\n  margin-bottom: 4px;\n  background: #fff;\n  border-radius: 4px;\n  appearance: none;\n  border: 0;\n  color: white;\n  font-weight: bold;\n  padding: 6px;\n  box-sizing: border-box;\n  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.15);\n}\n\n.EditablePanel-module__moveableButton--3sE_o.EditablePanel-module__active--oVpBp {\n  background-color: #e2e2e2;\n}\n.EditablePanel-module__moveableButton--3sE_o svg {\n  pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"moveableEditable": "EditablePanel-module__moveableEditable--b6WkQ",
	"moveableButton": "EditablePanel-module__moveableButton--3sE_o",
	"active": "EditablePanel-module__active--oVpBp"
};
module.exports = exports;
