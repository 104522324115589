import React, { useEffect, useState } from 'react'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import SVG from 'react-inlinesvg'
import cx from 'classnames'
import { connect } from 'react-redux'
import Gallery from './Gallery'
import { requestTemplates } from '../../actions/design_actions'
import { SearchIcon } from '../../assets/svg'
import types from '../../assets/jpg/types'
import styles from './NavBar.module.css'
import AskMorePopup from '../ask_more_popup/AskMorePopup'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CreateCustomTemplate from '../modal/CreateCustomTemplate'

function Index(props) {
  const { t } = useTranslation()
  const { getTemplates, designs } = props
  const [search, setSearch] = useState('')
  const [tags, setTags] = useState([])
  const [platform, setPlatform] = useState('all')
  const [platformDimensions, setPlatformDimensions] = useState([])
  const [showAskMore, setShowAskMore] = useState(false)

  useEffect(() => {
    getTemplates()
  }, [])

  function displayTypes() {
    switch (platform) {
      case 'all':
        return (
          <GeneratedTabs
            platformDimensions={platformDimensions}
            setPlatformDimensions={setPlatformDimensions}
            types={types}
          />
        )

      default:
        return (
          <GeneratedTabs
            platformDimensions={platformDimensions}
            setPlatformDimensions={setPlatformDimensions}
            types={types.filter(type => type.platform === platform)}
          />
        )
    }
  }

  const platforms = [
    { label: t('all'), value: 'all' },
    { label: t('social'), value: 'social' },
    { label: t('print'), value: 'print' },
    { label: t('digital'), value: 'digital' }
  ]
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])

  const handleCategories = event => {
    if (!categories.includes(event.target.value)) setCategories(prevstate => [...prevstate, event.target.value])
  }

  return (
    <div className="homeTemplate">
      <AskMorePopup isOpen={showAskMore} closeCallback={() => setShowAskMore(false)} />
      <header className={styles.headerBar}>
        <h1>{t('exploreAnyDesign')}</h1>
        <div className={styles.searchBar}>
          <SVG src={SearchIcon} />
          <input
            name="searchbar"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
            type="text"
            placeholder={t('search.AnyDesign.')}
          />
        </div>

        {/* <FormControl sx={{ m: 1, minWidth: 120}}>
        <Select
          value={categories[categories.length-1]}
          placeholder='Categories'
          onChange={handleCategories}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={()=> 'fdsfds'}
        >
         
          {categoriesAsset.map((category, idx) => (
            <MenuItem 
            key={idx}
            value={category.name}>{category.name}
            </MenuItem>
          ))}
          
        </Select>

      </FormControl> */}
      </header>

      {/* <div style={{display:'flex', padding:'15px'}}>
      
      
      {categories.map((category, idx) => (
            <Chip
              key={idx}
              clickable={true}
              label={category}
            />
          ))}

      </div> */}
      {/* Types */}
      <div className={styles.container}>
        <div className={styles.advHeading}>
          <h2 className="heading">{t('pickOnePlatformToDesignFor')}</h2>
          <ul>
            {platforms.map((item, idx) => (
              <li className={item.value === platform ? styles.active : ''} key={idx}>
                <button role="button" onClick={() => setPlatform(item.value)}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {displayTypes()}
      </div>

      {/* Templates */}
      <div className={styles.container}>
        <h2 className="heading">{t('exploreEndlessDesigns')}</h2>
        <div className={styles.galleryWrapper}>
          {/* {currentUser && <button className={styles.createDesign} onClick={() => setModal(true)} />}
          {showModal && <CreateCustomTemplate closeModal={() => setModal(false)} />} */}
          <Gallery
            {...props}
            size={platformDimensions}
            elements={tags.length ? designs.filter(design => tags.indexOf(design.category) > -1) || [] : designs}
          />
        </div>
      </div>
    </div>
  )
}

function CalculatedType({ item, active, setPlatformDimensions }) {
  const { t } = useTranslation()
  let history = useHistory()
  const [toggleShowModal, setToggleShowModal] = useState({ showModal: false })

  function calcHeight(dimensions) {
    const ratio = dimensions[0] / dimensions[1]
    if (ratio >= 1) {
      return '125px'
    } else {
      return `${125 / ratio}px`
    }
  }
  function calcWidth(dimensions) {
    const ratio = dimensions[0] / dimensions[1]
    if (ratio <= 1) {
      return '125px'
    } else {
      return `${125 * ratio}px`
    }
  }

  const handleClick = () => {
    setPlatformDimensions(item)
    if (item.label === 'Custom Dimensions') {
      setToggleShowModal({ showModal: true })
      console.log('ggggggggggggggggg')
    } else {
      history.push({
        pathname: '/user/explore',
        item: item
      })
    }
  }

  return (
    <>
      {toggleShowModal.showModal && (
        <CreateCustomTemplate closeModal={() => setToggleShowModal({ showModal: false })} />
      )}

      <div className={cx(styles.typeContainer, active && styles.typeActive)} onClick={() => handleClick()}>
        <div className={styles.typeBackground}>
          <div
            className={styles.type}
            style={{
              background: item.background,
              width: calcWidth(item.dimensions),
              height: calcHeight(item.dimensions)
            }}
          >
            <SVG src={item.icon} alt={item.label} className="etxt" />
          </div>
        </div>
        <span>{t(item.label)}</span>
      </div>
    </>
  )
}

function GeneratedTabs({ types, platformDimensions, setPlatformDimensions }) {
  return (
    <ScrollingCarousel>
      {types.map((item, key) => (
        <CalculatedType
          item={item}
          key={key}
          active={platformDimensions.id === item.id}
          setPlatformDimensions={setPlatformDimensions}
        />
      ))}
    </ScrollingCarousel>
  )
}

export default connect(
  state => {
    return {
      designs: Object.values(state.entities.templates).filter(t => t.public),
      currentUser: state.entities.users[state.session.id]
    }
  },
  dispatch => ({
    getTemplates: () => dispatch(requestTemplates('?withoutSession=true')),
    createDesign: design => dispatch(createDesign(design))
  })
)(Index)
