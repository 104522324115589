import { FormControlLabel, FormGroup, TextField, Typography, Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Settings.module.css'
import './settings.scss'

function LoginAndPass() {
  const { t } = useTranslation()
  const [state, setState] = useState({
    email: '',
    newPassword: '',
    confirmPassword: ''
  })

  const handleInputChanged = e => {
    const name = e.target.name
    const value = e.target.value
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    <div className="Settingstemplate">
      {/* header section */}
      <Typography className={styles.pageHeading}>Login and Password</Typography>
      <Typography className={styles.pageSubheading}>{t('PeopleVisiting')}</Typography>

      <form className="Settingstemplate__form">
        <FormGroup>
          <FormControlLabel
            control={<TextField name="email" value={state.email} onChange={handleInputChanged} fullWidth />}
            label={t('accountEmailAddress')}
            labelPlacement="top"
            className="Settingstemplate__Block"
          />
          <Button className="verificationBtn"> {t('sendVerification')}</Button>
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={<TextField name="newPassword" value={state.newPassword} onChange={handleInputChanged} fullWidth />}
            label={t('newPassword')}
            labelPlacement="top"
            className="Settingstemplate__Block"
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <TextField name="confirmPassword" value={state.confirmPassword} onChange={handleInputChanged} fullWidth />
            }
            label={t('confirmPassword')}
            labelPlacement="top"
            className="Settingstemplate__Block"
          />
        </FormGroup>

        <Button variant="contained" className="blueBtn">
          {t('save')}
        </Button>
      </form>
    </div>
  )
}

export default LoginAndPass
