// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RightPanel-module__rightPanel--3VctG {\n  height: 100%;\n  position: relative;\n  background-color: #ffffff;\n  min-width: 360px;\n  z-index: 100;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n\n  transition: 0.38s all ease;\n  transition-delay: 0.25s;\n}\n@media only screen and (max-width: 991px) {\n  .RightPanel-module__rightPanel--3VctG {\n    min-width: 270px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"rightPanel": "RightPanel-module__rightPanel--3VctG"
};
module.exports = exports;
