import React from 'react'
import { withTranslation } from 'react-i18next'
import scrollbar from '../scrollbar.module.css'
import CustomImage from './CustomImage'
import styles from './UploadsDrawer.module.css'

class UploadsDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: '',
      uploadedFile: null,
      title: 'temp',
      width: 0,
      height: 0
    }
    this.handleFile = this.handleFile.bind(this)
    this.addElement = this.addElement.bind(this)
  }

  componentDidMount() {
    const { fetchUserUploads, currentUser } = this.props

    this.setState({ images: fetchUserUploads(currentUser?.email) })
  }

  handleFile(e) {
    const reader = new FileReader()
    const file = e.currentTarget.files[0]
    reader.onloadend = () => {
      const image = new Image()
      image.onload = () => {
        this.setState(
          {
            imageUrl: reader.result,
            uploadedFile: file,
            title: file.name,
            width: image.width,
            height: image.height
          },
          this.uploadImage
        )
      }
      image.src = reader.result
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      this.setState({ imageUrl: '', uploadedFile: null })
    }
  }

  uploadImage() {
    const { title, uploadedFile, width, height } = this.state
    const { receiveUpload, updateUpload, currentUser } = this.props

    const formData = new FormData()
    formData.append('uploaded_image[title]', title)
    if (uploadedFile) {
      formData.append('uploaded_image[image]', uploadedFile)
      formData.append('uploaded_image[width]', width)
      formData.append('uploaded_image[height]', height)
    }

    updateUpload(uploadedFile, currentUser?.email).then(payload => {
      this.setState({ imageUrl: '', uploadedFile: null })
      receiveUpload(payload)
    })
  }

  addElement({ width, height, url }) {
    const { addElement, design } = this.props

    const dimensionsHeight = design.height
    const dimensionsWidth = design.width

    if (width > dimensionsWidth) {
      width = dimensionsWidth
    }

    if (height > dimensionsHeight) {
      height = dimensionsHeight
    }

    console.log(`DEsingAFTRR: ${width} ${height}`)
    const element = {
      elementableType: 'Image',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      elementableAttributes: {
        url,
        width,
        height
      }
    }
    addElement(element)
  }

  render() {
    const { images } = this.props
    const { width, height, imageUrl } = this.state
    return (
      <>
        <div className={styles.header}>
          <label htmlFor="upload-button" className={styles.uploadBtn}>
            {this.props.t('uploadAnImage')}
            <input
              id="upload-button"
              className={styles.hidden}
              type="file"
              accept="image/*"
              onChange={this.handleFile}
            />
          </label>
          {/* <button type="button" className="btn-blue">Upload an Image</button> */}
        </div>
        <div className={scrollbar.customScroll}>
          <div className={styles.uploadsDrawer}>
            <div className={styles.masonry}>
              {imageUrl && (
                <div
                  className={styles.masonItem}
                  style={{
                    flexGrow: (width * 85) / height,
                    flexBasis: (width * 95) / height
                  }}
                >
                  <i style={{ paddingBottom: `${(height / width) * 100.0}%` }} />
                  <img className={styles.tempImg} src={imageUrl} alt="" />
                  <div className={styles.temp}>
                    <div className={styles.loader} />
                  </div>
                </div>
              )}
              {images.map(image => (
                <div
                  key={image.id}
                  className={styles.masonItem}
                  style={{
                    flexGrow: (image.width * 85) / image.height,
                    flexBasis: (image.width * 95) / image.height
                  }}
                >
                  <i style={{ paddingBottom: `${(image.height / image.width) * 100.0}%` }} />
                  {/* <DesignIndexItem image={image} toggleModal={toggleModal} /> */}
                  {/* <img src={image.url} alt="" onClick={() => this.addElement(image)} className={styles.image} /> */}
                  <CustomImage image={image} addElement={this.addElement} />
                </div>
              ))}
            </div>
            {/* {images.map((image) => <img src={image.url} alt="" />)} */}
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(UploadsDrawer)
