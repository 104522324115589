// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserNav-module__userNav--1hFUz {\n  /* margin-left: auto; */\n  display: flex;\n  align-items: center;\n}\n.UserNav-module__userNav--1hFUz button {\n  min-width: 75px;\n  padding: 3px 15px;\n  height: auto;\n  min-height: 27px !important;\n  border-radius: 50px;\n  white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"userNav": "UserNav-module__userNav--1hFUz"
};
module.exports = exports;
