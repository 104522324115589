import React, { useState, useEffect } from 'react'
import PendingTable from './PendingTable'
import SVG from 'react-inlinesvg'
import { RingIcon, SearchIcon2 } from '../../../assets/svg'
import { useTranslation } from 'react-i18next'
export default function Pending({ templates, update }) {
  const { t } = useTranslation()
  return (
    <>
      <div className="tableHeader">
        <div>
          <h1>{t('pendingTemplates')}</h1>
          <span className="subtext">{`${t(
            'today'
          )} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</span>
        </div>
        <div className="rightSide">
          <div className="search">
            <input placeholder={t('search')} />
            <SVG src={SearchIcon2} />
          </div>
          <select>
            <option>{t('lastDays')}</option>
            <option>{t('lastDay')}</option>
            <option>{t('lastHours')}</option>
          </select>
          <button className="iconButton">
            <SVG src={RingIcon} />
          </button>
          <button className="iconButton"></button>
        </div>
      </div>
      <PendingTable templates={templates} update={update} />
    </>
  )
}
