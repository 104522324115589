import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography,
  Grid,
  Button
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const socialMediaOptions = [
  { name: 'facebook', backgroundColor: '#E3ECFF' },
  { name: 'instagram', backgroundColor: '#FFFAEB' },
  { name: 'twitter', backgroundColor: '#D3F1FF' },
  { name: 'linkedIn', backgroundColor: '#FFFAEB' },
  { name: 'pinterest', backgroundColor: '#FFE5E3' },
  { name: 'tumbler', backgroundColor: '#D6D6D6' },
  { name: 'website', backgroundColor: '#959595' },
  { name: 'link', backgroundColor: '#E8E8E8' }
]

function ShareDesignPopup({ isOpen, closeCallback }) {
  const [activeTab, setActiveTab] = useState(0)
  const [email, setEmail] = useState('')
  const { t } = useTranslation()
  const handleTabChanged = (event, value) => {
    setActiveTab(value)
  }

  const generateContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className="firstshareTab">
            <TextField value={email} onChange={e => setEmail(e.target.value)} placeholder={t('typeEmailHere')} />
            <Grid container justifyContent="space-between" alignItems="center" className="sharePeople">
              <Grid item className="sharePeople__Info">
                <Avatar src="" className="sharePeople__Image" />
                <Typography>{t('daisonjason')}</Typography>
              </Grid>
              <Grid item className="sharePeopleOwner">
                {t('owner')}
              </Grid>
            </Grid>
            <Button onClick={closeCallback} className="gray-btn">
              {t('back')}
            </Button>
          </div>
        )
      case 1:
        return (
          <div className="socialAppBlock">
            {socialMediaOptions.map(option => (
              <div className="socialApp">
                <div
                  style={{
                    backgroundColor: option.backgroundColor,
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%'
                  }}
                ></div>
                <Typography className="socialApp__name">{t(option.name)}</Typography>{' '}
              </div>
            ))}
          </div>
        )
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeCallback}>
      <div className="sharePopup">
        <DialogTitle className="sharePopup__Head">
          <Typography className="sharePopup__Title">{t('shareYourDesigns')}</Typography>
        </DialogTitle>
        <DialogContent className="sharePopup__tabs">
          <Tabs value={activeTab} onChange={handleTabChanged}>
            <Tab label={t('emailLink')} />
            <Tab label={t('socialMedia')} />
          </Tabs>
          <div className="sharePopup__tabContent">{generateContent()}</div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default ShareDesignPopup
