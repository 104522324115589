// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".scrollbar-module__customScroll--2Oo29 {\n  overflow-y: auto;\n  overflow-x: hidden;\n  height: calc(100% - 72px);\n  margin-right: 4px;\n  margin-top: 4px;\n}\n\n.scrollbar-module__customScroll--2Oo29:hover::-webkit-scrollbar-thumb {\n  background-color: #5f646b;\n}\n\n.scrollbar-module__customScroll--2Oo29::-webkit-scrollbar {\n  width: 8px;\n  background-color: #293039;\n}\n\n.scrollbar-module__customScroll--2Oo29::-webkit-scrollbar-thumb {\n  background: #293039;\n  border-radius: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"customScroll": "scrollbar-module__customScroll--2Oo29"
};
module.exports = exports;
