// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".with_modal-module__modalBackground--3L2Gk {\n  position: absolute;\n  background-color: rgba(0, 0, 0, 0.4);\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  overflow-y: auto;\n  padding: 16px;\n  z-index: 99;\n}\n\n.with_modal-module__wrapper--Ghh3A {\n  width: 100%;\n  max-width: 1376px;\n  /* max-width: 920px; */\n  position: relative;\n  margin: auto 48px;\n}\n\n.with_modal-module__close--ioVUC {\n  position: absolute;\n  top: 0;\n  right: -40px;\n  min-width: 32px;\n  width: 32px;\n  height: 32px;\n  background-color: rgba(0, 0, 0, 0.2);\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  transition: background-color 0.3s;\n  border-color: transparent;\n}\n\n.with_modal-module__close--ioVUC:hover {\n  border-color: transparent;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n", ""]);
// Exports
exports.locals = {
	"modalBackground": "with_modal-module__modalBackground--3L2Gk",
	"wrapper": "with_modal-module__wrapper--Ghh3A",
	"close": "with_modal-module__close--ioVUC"
};
module.exports = exports;
