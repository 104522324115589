// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__success_centerContent--2Qfvs {\n  text-align: center;\n  padding: 40px 40px 25px !important;\n}\n\n/* .successModel {\n    padding: 40px;\n  } */\n\n.styles-module__success_centerContentIcon--IXyTB svg {\n  width: 65px;\n  height: 65px;\n}\n\n.styles-module__success_centerContentTitle--2_I9D {\n  font-size: 20px !important;\n  color: #000 !important;\n  line-height: 28px !important;\n  margin-top: 10px !important;\n  font-weight: 600 !important;\n}\n\n.styles-module__success_centerContentText--2aj0H {\n  font-size: 12px !important;\n  color: #555 !important;\n  line-height: 18px !important;\n  margin-top: 2px !important;\n}\n\n.styles-module__success_centerContentBtn--2UkvI {\n  justify-content: center !important;\n}\n\n.styles-module__success_centerContentBtn--2UkvI button {\n  border: 1px solid #3f51b5 !important;\n  background-color: #3f51b5 !important;\n  color: #fff !important;\n  text-transform: capitalize !important;\n  font-size: 14px !important;\n  line-height: 16px !important;\n  height: auto;\n  border-radius: 50px !important;\n  margin-top: 15px !important;\n  padding: 5px 18px !important;\n  min-width: 130px;\n}\n\n.styles-module__success_centerContentBtn--2UkvI button:last-child {\n  border: 1px solid #000 !important;\n  background-color: #000 !important;\n}\n\n@media only screen and (max-width: 767px) {\n  .styles-module__success_centerContent--2Qfvs {\n    padding: 30px 20px 25px !important;\n  }\n  .styles-module__success_centerContentBtn--2UkvI button {\n    min-width: 122px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"success_centerContent": "styles-module__success_centerContent--2Qfvs",
	"success_centerContentIcon": "styles-module__success_centerContentIcon--IXyTB",
	"success_centerContentTitle": "styles-module__success_centerContentTitle--2_I9D",
	"success_centerContentText": "styles-module__success_centerContentText--2aj0H",
	"success_centerContentBtn": "styles-module__success_centerContentBtn--2UkvI"
};
module.exports = exports;
