// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CardData-module__bar--2Xdom {\n  display: flex;\n  margin: 1vh 0 1vh 0;\n}\n.CardData-module__cardIcon--2itmT {\n  width: 50px;\n  height: 50px;\n}\n.CardData-module__main--3wneb[disabled] {\n  pointer-events: none;\n  opacity: 0.7;\n}\n", ""]);
// Exports
exports.locals = {
	"bar": "CardData-module__bar--2Xdom",
	"cardIcon": "CardData-module__cardIcon--2itmT",
	"main": "CardData-module__main--3wneb"
};
module.exports = exports;
