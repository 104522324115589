import {
  airplaneImage,
  arrowImage,
  batteryImage,
  circleImage,
  cloudImage,
  cornerImage,
  cubeImage,
  curvedRibbonImage,
  dropImage,
  eyeImage,
  fireImage,
  flashImage,
  handImage,
  heartImage,
  heartLeafImage,
  hexagonImage,
  leafImage,
  lineImage,
  messageImage,
  pentagonImage,
  pyramidChartImage,
  pyramidImage,
  rectangleImage,
  ribbonImage,
  rightTriangleImage,
  rocketImage,
  roundedRectangleImage,
  roundedSquareImage,
  sendImage,
  shieldImage,
  speechImage,
  starImage,
  sunImage,
  triangleImage,
  triangularImage,
  bookmarkImage
} from '../../assets/svg/shapes'

export const getShapes = () => [
  { id: 1, shape: 'circle', image: circleImage },
  { id: 2, shape: 'rectangle', image: rectangleImage },
  { id: 3, shape: 'triangle', image: triangleImage },
  { id: 4, shape: 'hexagon', image: hexagonImage },
  { id: 5, shape: 'pentagon', image: pentagonImage },
  { id: 6, shape: 'rounded-square', image: roundedSquareImage },
  { id: 7, shape: 'heart', image: heartImage },
  { id: 8, shape: 'star', image: starImage },
  { id: 9, shape: 'right-triangle', image: rightTriangleImage },
  { id: 10, shape: 'airplane', image: airplaneImage },
  { id: 11, shape: 'arrow', image: arrowImage },
  { id: 12, shape: 'battery', image: batteryImage },
  { id: 13, shape: 'cloud', image: cloudImage },
  { id: 14, shape: 'corner', image: cornerImage },
  { id: 15, shape: 'cube', image: cubeImage },
  { id: 16, shape: 'curved-ribbon', image: curvedRibbonImage },
  { id: 17, shape: 'drop', image: dropImage },
  { id: 18, shape: 'eye', image: eyeImage },
  { id: 19, shape: 'fire', image: fireImage },
  { id: 20, shape: 'flash', image: flashImage },
  { id: 21, shape: 'hand', image: handImage },
  { id: 22, shape: 'heart-leaf', image: heartLeafImage },
  { id: 23, shape: 'leaf', image: leafImage },
  { id: 24, shape: 'line', image: lineImage },
  { id: 25, shape: 'message', image: messageImage },
  { id: 26, shape: 'pyramid', image: pyramidImage },
  { id: 27, shape: 'pyramid-chart', image: pyramidChartImage },
  { id: 28, shape: 'ribbon', image: ribbonImage },
  { id: 29, shape: 'rocket', image: rocketImage },
  { id: 30, shape: 'rounded-rectangle', image: roundedRectangleImage },
  { id: 31, shape: 'send', image: sendImage },
  { id: 32, shape: 'shield', image: shieldImage },
  { id: 33, shape: 'speech', image: speechImage },
  { id: 34, shape: 'sun', image: sunImage },
  { id: 35, shape: 'triangular', image: triangularImage },
  { id: 36, shape: 'bookmark', image: bookmarkImage }
]

export default { getShapes }
