import { CREATE_BRAND, RECEIVE_BRANDS, RECEIVE_BRAND, DELETE_BRAND, EDIT_BRAND } from '../../actions/brands_actions'

const initialState = {}

const brandsReducer = (state = initialState, action) => {
  const brand = action.payload
  const emptyState = {}
  switch (action.type) {
    case CREATE_BRAND:
      return { ...state, [brand.data.result.brand.id]: brand.data.result.brand }
    case RECEIVE_BRANDS:
      action.payload.data.result.forEach(element => {
        emptyState[element.id] = element
      })
      return emptyState
    case RECEIVE_BRAND:
      return { ...state, [brand.id]: brand }
    case DELETE_BRAND:
      let deletedId = action.payload.data.content.id
      delete state[deletedId]
      return state
    case EDIT_BRAND:
      return { ...state, [action.payload.data.result.id]: action.payload.data.result }
    default:
      return state
  }
}

export default brandsReducer
