import { RECEIVE_TEMPLATES, RECEIVE_UPDATED_DESIGN } from '../../actions/design_actions'
import types from '../../assets/jpg/types'

const attachSizeCategory = template => {
  const size = JSON.stringify([template.width, template.height])

  for (let i = 0; i < types.length; i++) {
    if (size === JSON.stringify(types[i].dimensions)) {
      template.sizeCategory = types[i]
      return
    }
  }

  template.sizeCategory = types[0]
}

const templates = (state = {}, action) => {
  const emptyState = {}
  switch (action.type) {
    case RECEIVE_TEMPLATES:
      action.payload.forEach(element => {
        emptyState[element.id] = element
        attachSizeCategory(emptyState[element.id])
      })
      //console.log(`RECEIVE_TEMPLATES: ${JSON.stringify(emptyState)}`)

      return emptyState

    case RECEIVE_UPDATED_DESIGN:
      console.log(`RECEIVE_TEMPLATE: ${JSON.stringify(action.payload)}`)
      return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload } }
    default:
      return state
  }
}

export default templates
