// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UnsplashDrawer-module__unsplashDrawer--9K8sV {\n  width: 320px;\n  display: flex;\n  flex-direction: column;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 8px;\n}\n\n.UnsplashDrawer-module__masonry--2kWiH {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.UnsplashDrawer-module__masonry--2kWiH::after {\n  content: '';\n  flex-grow: 999999999;\n}\n\n.UnsplashDrawer-module__masonItem--2HVlW {\n  margin: 0 8px 8px 0;\n  position: relative;\n}\n\n.UnsplashDrawer-module__masonItem--2HVlW > i {\n  display: block;\n}\n\n.UnsplashDrawer-module__masonItem--2HVlW > div {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  vertical-align: bottom;\n}\n\n@media only screen and (max-width: 991px) {\n  .UnsplashDrawer-module__unsplashDrawer--9K8sV {\n    width: 240px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"unsplashDrawer": "UnsplashDrawer-module__unsplashDrawer--9K8sV",
	"masonry": "UnsplashDrawer-module__masonry--2kWiH",
	"masonItem": "UnsplashDrawer-module__masonItem--2HVlW"
};
module.exports = exports;
