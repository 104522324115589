// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DimensionView-module__moveableDimension--17mH6 {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: #ffffff;\n  border-radius: 2px;\n  padding: 2px 4px;\n  color: #111111;\n  font-size: 13px;\n  white-space: nowrap;\n  font-weight: bold;\n  will-change: transform;\n  transform: translate(-50%, 0px);\n  box-sizing: border-box;\n  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.15);\n}\n", ""]);
// Exports
exports.locals = {
	"moveableDimension": "DimensionView-module__moveableDimension--17mH6"
};
module.exports = exports;
