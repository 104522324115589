import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Suspense } from 'react'
import AppContainer from './app_container'
import { getUser } from '../actions/session_actions'
import '../components/Language/i18nextInit'

const Root = ({ store, onGetUser }) => {
  useEffect(() => {
    onGetUser()
  }, [])

  return (
    <Suspense fallback="...">
      <Provider store={store}>
        <HashRouter>
          <AppContainer />
        </HashRouter>
      </Provider>
    </Suspense>
  )
}

const mapDispatchToProps = dispatch => ({
  onGetUser: () => dispatch(getUser())
})

export default connect(null, mapDispatchToProps)(Root)
