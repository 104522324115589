// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkout-module__container--2K51T {\n  height: 100vh;\n  padding: 4vh;\n  font-size: 0.7vw;\n}\n\n.Checkout-module__bar--Q9Rxv {\n  display: flex;\n  justify-content: space-between;\n  margin: 4vh 0 0 0;\n  align-items: baseline;\n}\n\n.Checkout-module__bar--Q9Rxv p {\n  font-size: 1em;\n}\n\n.Checkout-module__trial_card--2VWc- {\n  padding: 0;\n}\n.Checkout-module__trial_card_header--P-Mfg {\n  display: flex;\n  justify-content: space-between;\n  font-weight: bold;\n  font-size: medium;\n}\n.Checkout-module__trial_card_header_inner--1dxWb {\n  display: flex;\n  align-items: baseline;\n}\n.Checkout-module__trial_card_header_inner--1dxWb span {\n  font-weight: normal;\n  font-size: 0.7em;\n  margin-left: 0.5em;\n}\n\n.Checkout-module__trial_card--2VWc- p {\n  margin-top: 1vh;\n  margin-bottom: 1vh;\n}\n\n.Checkout-module__priceBar--13PYz {\n  display: flex;\n  align-items: baseline;\n}\n.Checkout-module__priceBar--13PYz p {\n  font-size: 2vw;\n  font-weight: bold;\n}\n.Checkout-module__priceBar--13PYz span {\n  margin-left: 5%;\n  font-size: medium;\n  color: 'silver';\n}\n.Checkout-module__cardHeader--3Y9uX {\n  display: flex;\n  align-items: center;\n}\n.Checkout-module__cardHeader--3Y9uX p {\n  font-weight: bolder;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Checkout-module__container--2K51T",
	"bar": "Checkout-module__bar--Q9Rxv",
	"trial_card": "Checkout-module__trial_card--2VWc-",
	"trial_card_header": "Checkout-module__trial_card_header--P-Mfg",
	"trial_card_header_inner": "Checkout-module__trial_card_header_inner--1dxWb",
	"priceBar": "Checkout-module__priceBar--13PYz",
	"cardHeader": "Checkout-module__cardHeader--3Y9uX"
};
module.exports = exports;
