const categories = [
  {
    name: 'restaurants',
    backgroundColor: '#FFE6E6',
    color: '#000'
  },
  {
    name: 'lawyer',
    backgroundColor: '#C9C9C9',
    color: '#000'
  },
  {
    name: 'makeup',
    backgroundColor: '#FFE0FE',
    color: '#000'
  },
  {
    name: 'workout',
    backgroundColor: '#FFACAC',
    color: '#000'
  },
  {
    name: 'fitness',
    backgroundColor: '#C1FFEE',
    color: '#000'
  },
  {
    name: 'food',
    backgroundColor: '#FFF3CB',
    color: '#000'
  },
  {
    name: 'barber',
    backgroundColor: '#464646',
    color: '#fff'
  },
  {
    name: 'cars',
    backgroundColor: ' #CECECE',
    color: '#000'
  },
  {
    name: 'nails',
    backgroundColor: '#FFF0D0',
    color: '#000'
  },
  {
    name: 'events',
    backgroundColor: '#E2E3FF',
    color: '#000'
  },
  {
    name: 'hairStyling',
    backgroundColor: '#F7DEFF',
    color: '#000'
  },
  {
    name: 'fastFood',
    backgroundColor: '#FFF8C3',
    color: '#000'
  },
  {
    name: 'cleaning',
    backgroundColor: '#F5F5F5',
    color: '#000'
  },
  {
    name: 'carFixing',
    backgroundColor: '#CEF9FF',
    color: '#000'
  }
]

export default categories
