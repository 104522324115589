import React from 'react'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { useTranslation } from 'react-i18next'
import { googleIcon, facebookIcon, appleIcon } from './AuthIcons'

const ExternalSignup = ({ changeView, animate }) => {
  const { t } = useTranslation()
  return (
    <div className={animate ? 'animated auth-form' : 'auth-form'}>
      <div className="form-container">
        <h2>{t('logInIntoPynt')}</h2>
        <p>{t('signInQuicklyUsing')}</p>
        {/* <button type="button" className="google btn-outline" disabled>
      {googleIcon}
      <span>Sign up with Google</span>
    </button-->
    <button type="button" onClick={demoLogin} className="demo btn-outline">
      {facebookIcon}
      <i>
        <FiUserCheck />
      </i>
      <span>Log in as Demo User</span>
    </button>*/}
        <div className="auth-login-buttons">
          <button className="btn-secondary">
            <SVG src={googleIcon} className="login-icon" />
            {t('google')}
          </button>
          <button className="btn-secondary">
            <SVG src={facebookIcon} className="login-icon" />
            {t('facebook')}
          </button>
          <button className="btn-secondary">
            <SVG src={appleIcon} className="login-icon" />
            {t('apple')}
          </button>
        </div>
        <p className="auth-or">{t('or')}</p>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <button
            type="button"
            style={{ margin: 'auto', width: '100%', marginTop: '10px', textDecoration: 'none' }}
            className="btn-blue"
            onClick={changeView}
          >
            {t('logIn')}
          </button>
        </Link>
        <p className="auth-text">
          {t('dontHaveAnAccount')}{' '}
          <a className="auth-text-red" onClick={changeView}>
            {t('signUpForFree')}
          </a>
          <br />
          <Link className="auth-text-blue" to="/signup">
            {t('forgotYourPassword')}
          </Link>
        </p>
        {/* <small>
          Don&apos;t want to sign up?&nbsp;
          <a href="#">Log in as demo user</a>
        </small> */}
      </div>
    </div>
  )
}

export default ExternalSignup
