// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DesignBrowser-module__container--3hCPy {\n  flex-grow: 1;\n  padding: 3vh 3vw 3vh 3vw;\n}\n\n.DesignBrowser-module__masonry--3_WRN {\n  padding: 5% 0 5% 0;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n/* .masonItem {\n    height: 250px;\n    flex: 1 0 auto;\n    margin: 0 24px 56px 0;\n    border: 1px solid #ddddde;\n    border-radius: 4px;\n  } */\n\n.DesignBrowser-module__masonry--3_WRN::after {\n  content: '';\n  flex-grow: 999999999;\n}\n\n.DesignBrowser-module__masonItem--1zbqT {\n  margin: 0 24px 56px 0;\n  position: relative;\n}\n\n.DesignBrowser-module__masonItem--1zbqT > i {\n  display: block;\n}\n\n.DesignBrowser-module__masonItem--1zbqT > div {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  vertical-align: bottom;\n}\n\n.DesignBrowser-module__emptyWrapper--BMHii {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 65vh;\n}\n\n.DesignBrowser-module__empty--SB6p1 {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.DesignBrowser-module__empty--SB6p1 svg {\n  width: 100px;\n  margin-bottom: 15px;\n}\n\n.DesignBrowser-module__empty--SB6p1 h2 {\n  font-size: 21px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n\n.DesignBrowser-module__empty--SB6p1 span {\n  font-size: 12px;\n  font-weight: 300;\n  margin-bottom: 15px;\n}\n\n.DesignBrowser-module__empty--SB6p1 button {\n  background-color: #005ad1;\n  border-radius: 25px;\n  padding: 5px 15px;\n  color: white;\n}\n.DesignBrowser-module__indexTitle--2w-iZ {\n  margin-bottom: 32px;\n  font-size: 2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "DesignBrowser-module__container--3hCPy",
	"masonry": "DesignBrowser-module__masonry--3_WRN",
	"masonItem": "DesignBrowser-module__masonItem--1zbqT",
	"emptyWrapper": "DesignBrowser-module__emptyWrapper--BMHii",
	"empty": "DesignBrowser-module__empty--SB6p1",
	"indexTitle": "DesignBrowser-module__indexTitle--2w-iZ"
};
module.exports = exports;
