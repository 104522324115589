// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal-module__modal--2SNGh {\n  width: 100%;\n  /* max-width: 920px; */\n  /* max-width: 1376px; */\n  border-radius: 8px;\n  background-color: white;\n  padding: 32px;\n  box-sizing: border-box;\n  /* margin: 16px 0; */\n}\n", ""]);
// Exports
exports.locals = {
	"modal": "modal-module__modal--2SNGh"
};
module.exports = exports;
