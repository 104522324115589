// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LayerEditor-module__layerPanel--1wVvA {\n  height: 100%;\n  position: relative;\n  background-color: #ffffff;\n  max-width: 360px;\n  padding: 0 15px;\n  transition: 0.38s all ease;\n}\n\n.LayerEditor-module__panelHead--3LWqo {\n  font-size: 1.8rem;\n  color: #000;\n  font-weight: 600;\n  padding: 0 14px;\n  margin-bottom: 8px;\n  user-select: none;\n}\n\n.LayerEditor-module__elementsContainer--Clb1Y {\n  padding: 14px;\n}\n\n.LayerEditor-module__layerItemContainer--2jnbv {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.LayerEditor-module__layerItemContainer--2jnbv > svg {\n  margin: 0 12px;\n  width: 20px;\n  opacity: 0.4;\n  transition: 0.3s;\n}\n\n.LayerEditor-module__layerItem--13uH7 {\n  border-radius: 14px;\n  padding: 18px;\n  box-sizing: border-box;\n  width: 100%;\n  background-color: #f7f7f7;\n  transition: 0.3s;\n  font-size: 1.4em;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  user-select: none;\n  flex-wrap: wrap;\n}\n\n.LayerEditor-module__layerItemContainer--2jnbv:hover .LayerEditor-module__layerItem--13uH7,\n.LayerEditor-module__layerItemContainer--2jnbv.LayerEditor-module__active--18qjh .LayerEditor-module__layerItem--13uH7 {\n  background-color: #dfeaf8;\n}\n\n.LayerEditor-module__layerItemContainer--2jnbv:hover > svg,\n.LayerEditor-module__layerItemContainer--2jnbv.LayerEditor-module__active--18qjh > svg {\n  opacity: 1;\n}\n\n.LayerEditor-module__layerOrder--1AySr {\n  width: 100%;\n}\n\n.LayerEditor-module__layerImage--2ABUv img {\n  display: block;\n  width: auto;\n  object-fit: scale-down;\n  height: 60px;\n}\n", ""]);
// Exports
exports.locals = {
	"layerPanel": "LayerEditor-module__layerPanel--1wVvA",
	"panelHead": "LayerEditor-module__panelHead--3LWqo",
	"elementsContainer": "LayerEditor-module__elementsContainer--Clb1Y",
	"layerItemContainer": "LayerEditor-module__layerItemContainer--2jnbv",
	"layerItem": "LayerEditor-module__layerItem--13uH7",
	"active": "LayerEditor-module__active--18qjh",
	"layerOrder": "LayerEditor-module__layerOrder--1AySr",
	"layerImage": "LayerEditor-module__layerImage--2ABUv"
};
module.exports = exports;
