import React from 'react'
import { connect } from 'react-redux'

import { requestTemplates } from '../../../../actions/design_actions'

import styles from './styles.module.css'
import scrollbar from '../scrollbar.module.css'
import DrawerSearch from '../DrawerSearch'

class TemplatesDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      page: 1,
      popular: true
    }
    this.addElement = this.addElement.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    this.props.getTemplates()
  }

  addElement(elements) {
    const { addElement } = this.props

    if (elements && elements.length) {
      elements.map(element => addElement(element))
    }
  }
  handleSearch(e) {
    this.setState({ query: e.target.value })
  }
  clearSearch() {
    this.setState({ query: '', popular: true })
  }

  submitSearch(e) {}

  render() {
    const { templates, currentUser } = this.props
    const { query, popular } = this.state
    return (
      <>
        <DrawerSearch
          placeholder={`[Selected type: search templates]`}
          handleSearch={this.handleSearch}
          handleClear={this.clearSearch}
          value={query}
          handleSubmit={this.submitSearch}
        />
        <div className={scrollbar.customScroll}>
          <div className={styles.elements}>
            <div className={styles.itemList}>
              {templates.map(template => (
                <div key={template.id} className={styles.item} onClick={() => this.addElement(template.elements)}>
                  <img
                    src={encodeURI(
                      `${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${template.email}/Canvas/All designs/${template.id}.png`
                    )}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.entities.users[state.session.id],
    templates: Object.values(state?.entities?.templates) || []
  }),
  dispatch => ({
    getTemplates: () => dispatch(requestTemplates('?withoutSession=true'))
  })
)(TemplatesDrawer)
