import fbIcon from './facebook.svg'
import instagramIcon from './instagram.svg'
import logoIcon from './logo.svg'
import twitterIcon from './twitter.svg'
import YTIcon from './youtube.svg'

export default [
  {
    id: 1,
    label: 'Custom Dimensions',
    description: 'custom',
    icon: logoIcon,
    background: '#E3FFF6',
    dimensions: [500, 500],
    platform: 'social'
  },
  {
    id: 2,
    label: 'poster',
    description: '18 x 24 in',
    icon: logoIcon,
    background: '#E3FFF6',
    dimensions: [1728, 2304],
    platform: 'print'
  },
  {
    id: 3,
    label: 'facebookCover',
    description: '820 x 312 px',
    icon: fbIcon,
    background: '#E3ECFF',
    dimensions: [820, 312],
    platform: 'social'
  },
  {
    id: 4,
    label: 'presentation',
    description: '1920 x 1080 px',
    icon: logoIcon,
    background: '#E3FFF6',
    dimensions: [1920, 1080],
    platform: 'digital'
  },
  {
    id: 5,
    label: 'businessCard',
    description: '3.5 x 2 in',
    icon: logoIcon,
    background: '#E3FFF6',
    dimensions: [336, 192],
    platform: 'digital'
  },
  {
    id: 6,
    label: 'logo',
    description: '500 x 500 px',
    icon: logoIcon,
    background: '#E3FFF6',
    dimensions: [500, 500],
    platform: 'digital'
  },
  {
    id: 7,
    label: 'instagramStory',
    description: '1080 x 1920 px',
    icon: instagramIcon,
    background: 'linear-gradient(45deg, rgba(255,250,235,1) 0%, rgba(246,230,246,1) 100%)',
    dimensions: [1080, 1920],
    platform: 'social'
  },
  {
    id: 8,
    label: 'facebookPost',
    description: '940 x 788 px',
    icon: fbIcon,
    background: '#E3ECFF',
    dimensions: [940, 788],
    platform: 'social'
  },
  {
    id: 9,
    label: 'instagramPost',
    description: '1080 x 1080 px',
    icon: instagramIcon,
    background: 'linear-gradient(45deg, rgba(255,250,235,1) 0%, rgba(246,230,246,1) 100%)',
    dimensions: [1080, 1080],
    platform: 'social'
  },
  {
    id: 10,
    label: 'youtubeCover',
    description: '2560 x 1440 px',
    icon: YTIcon,
    background: '#FFE5E3',
    dimensions: [2560, 1440],
    platform: 'social'
  },
  {
    id: 11,
    label: 'facebookStory',
    description: '1080 x 1920 px',
    icon: fbIcon,
    background: '#E3ECFF',
    dimensions: [1080, 1920],
    platform: 'social'
  },
  {
    id: 12,
    label: 'twitterPost',
    description: '1200 x 1200 px',
    icon: twitterIcon,
    background: '#D3F1FF',
    dimensions: [1200, 1200],
    platform: 'social'
  }
]
