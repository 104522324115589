import React from 'react'
import { Link } from 'react-router-dom'
import { FiMoreHorizontal } from 'react-icons/fi'
import IndexItemDropdownContainer from './index_item_dropdown_container'
import styles from './DesignIndexItem.module.css'
import { useState, useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import PanoramaIcon from '@mui/icons-material/Panorama'
import axios from 'axios'

function DesignIndexItem({ design }) {
  const [state, setState] = useState({ dropdown: false, direction: true, hasImage: true })
  const { t } = useTranslation()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'design',
    item: design,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const wrapperRef = useRef(null)

  const toggleDropdown = event => {
    setState(state => {
      return { dropdown: !state.dropdown, direction: event.clientX < 574 }
    })
  }
  // useEffect(() => {
  //   if (state.hasImage===null) {
  //     tryRequire();
  // }

  // }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setState(state => ({ ...state, dropdown: false }))
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const tryRequire = async () => {
    axios
      .head(
        `${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${design.email}/Canvas/All designs/${design.id}.png`,
        {
          withCredentials: true
        }
      )
      .then(result => console.log(result))
      .catch(e => console.log(e))

    //  try {
    //   const result = await fetch(`${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${design.email}/Canvas/All designs/${design.id}.png`, { method: 'HEAD' })
    //   setState(state=>({...state,hasImage:result}))
    //  }
    //  catch {
    //    console.log('EERRRRRRRRRRRRR')
    //   setState(state=>({...state,hasImage:false}))
    //  }
  }

  return (
    <div ref={drag}>
      <div className={styles.card} ref={wrapperRef} style={{ border: isDragging ? '2px solid red' : '0px' }}>
        <Link to={`/design/${design.id}`}>
          {
            // tryRequire(encodeURI(
            //   `${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${design.email}/Canvas/All designs/${design.id}.png`
            // )) ?
            state.hasImage ? (
              <img
                src={encodeURI(
                  `${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${design.email}/Canvas/All designs/${design.id}.png`
                )}
                alt=""
                onError={() => setState(state => ({ ...state, hasImage: false }))}
              />
            ) : (
              <div className={styles.no_image}>
                <PanoramaIcon className={styles.preview_icon} sx={{ fontSize: 50 }} />
                <p>Open preview</p>
              </div>
            )
          }
        </Link>

        <div className={styles.wrap}>
          <div className={`${styles.toggle} ${state.dropdown ? styles.active : ''}`}>
            <button type="button" className="btn-item" onClick={toggleDropdown}>
              <FiMoreHorizontal />
            </button>
          </div>
        </div>

        {design && state.dropdown ? (
          <IndexItemDropdownContainer design={design} toggleDropdown={toggleDropdown} direction={state.direction} />
        ) : (
          ''
        )}
      </div>
      <div className={styles.titleBlock}>
        <div className={styles.subTitle}>{`${t('modifiedAt')} ${new Date(
          design?.updatedAt
        ).toLocaleDateString()}`}</div>
      </div>
    </div>
  )
}

export default DesignIndexItem
