// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DesignDrawer-module__designDrawer--3E0LN {\n  height: 100%;\n  background-color: #293039;\n  position: relative;\n  display: flex;\n}\n\n.DesignDrawer-module__toolsNav--2u8zv {\n  flex-basis: 80px;\n  flex-shrink: 0;\n  background-color: #1d1c23;\n  position: relative;\n  z-index: 11;\n}\n\n.DesignDrawer-module__buttonsNav--3jwJw {\n  position: absolute;\n  z-index: 3;\n  top: 10px;\n}\n\n.DesignDrawer-module__drawer--174k4 {\n  background-color: transparent;\n  width: 360px;\n  height: 100%;\n  margin-left: -360px;\n  transition: all 1s ease;\n}\n\n.DesignDrawer-module__highlight--3CJTj {\n  width: 72px;\n  height: 72px;\n  position: absolute;\n  z-index: 2;\n  top: 0px;\n  transition: transform 0.2s ease;\n}\n\n.DesignDrawer-module__highlightClosed--3iVeY {\n  width: 72px;\n  height: 72px;\n  position: absolute;\n  z-index: 2;\n  top: 0px;\n  transition: transform 0s ease;\n}\n\n.DesignDrawer-module__background--1g4AF {\n  width: 57px;\n  height: 58px;\n  background-color: #3a74bc;\n  opacity: 1;\n  transition: opacity 0.3s ease;\n  margin-left: 9px;\n  margin-right: 7px;\n  border-radius: 1rem;\n}\n\n.DesignDrawer-module__hidden--34syx {\n  opacity: 0;\n}\n\n.DesignDrawer-module__handle--3qCV5 {\n  position: absolute;\n  top: 50%;\n  right: -19px;\n  margin-top: -40px;\n  z-index: 10;\n}\n\n.DesignDrawer-module__container--1iU0R {\n  position: relative;\n}\n\n.DesignDrawer-module__handleIcon--1fFoD {\n  position: absolute;\n  top: 50%;\n  font-size: 1.6rem;\n  margin-top: -12px;\n  right: 5px;\n  color: #fff;\n}\n\n.DesignDrawer-module__showDrawer--2DK8W {\n  margin-left: 0px;\n}\n\n@media only screen and (max-width: 991px) {\n  .DesignDrawer-module__designDrawer--3E0LN {\n    position: absolute;\n    left: 80px;\n    z-index: 10;\n  }\n  .DesignDrawer-module__drawer--174k4 {\n    width: 270px;\n    margin-left: -270px;\n  }\n  .DesignDrawer-module__showDrawer--2DK8W {\n    margin-left: 0px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"designDrawer": "DesignDrawer-module__designDrawer--3E0LN",
	"toolsNav": "DesignDrawer-module__toolsNav--2u8zv",
	"buttonsNav": "DesignDrawer-module__buttonsNav--3jwJw",
	"drawer": "DesignDrawer-module__drawer--174k4",
	"highlight": "DesignDrawer-module__highlight--3CJTj",
	"highlightClosed": "DesignDrawer-module__highlightClosed--3iVeY",
	"background": "DesignDrawer-module__background--1g4AF",
	"hidden": "DesignDrawer-module__hidden--34syx",
	"handle": "DesignDrawer-module__handle--3qCV5",
	"container": "DesignDrawer-module__container--1iU0R",
	"handleIcon": "DesignDrawer-module__handleIcon--1fFoD",
	"showDrawer": "DesignDrawer-module__showDrawer--2DK8W"
};
module.exports = exports;
