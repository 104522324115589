// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Gallery-module__design--2cRc1 {\n  cursor: pointer;\n}\n\n.Gallery-module__design--2cRc1 img {\n  border-radius: 20px;\n\n  max-height: 200px;\n  min-height: 100px;\n\n  min-width: 150px;\n}\n", ""]);
// Exports
exports.locals = {
	"design": "Gallery-module__design--2cRc1"
};
module.exports = exports;
