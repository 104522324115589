import React, { Fragment } from 'react'
import { Typography,Button } from '@material-ui/core'
import SVG from 'react-inlinesvg'
import styles from './DeletedDesigns.module.css'
import { UploadIcon } from '../../assets/svg'
import {  useEffect , useState} from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { fetchUserUploads, receiveUpload, updateUpload, deleteUpload} from '../../actions/uploaded_image_actions'
import UploadCard from './UploadCard'

function Uploads({currentUser, fetchUserUploads, images,deleteUpload}) {
  const { t } = useTranslation();
 
  useEffect(() => {
    fetchUserUploads(currentUser?.email)
  }, [])

  console.log(JSON.stringify(images))
  
  if (!images.length) {
    return (
      <div className={styles.centerBox}>
        <div className={styles.centerBoxImage}>
          <SVG src={UploadIcon} style={{ height: '125px', width: '75px' }} />
        </div>
        <Typography className={styles.centerBoxTitle}>{t('youHaveNoUploadsYet')}</Typography>
        <Typography className={styles.centerBoxText}>{t('yourUploadedTemplates')}</Typography>
      </div>
    )
  }

  return (
    <div className={styles.uploadTemplates}>
      <h2>Uploads</h2>
      <div className={styles.uploadsAllCards}>
        {images.map((image, idx) => (
          <Fragment key={idx}>
            <UploadCard image={image}
            render={closeMenu => (
              <>
                <Button
                 onClick={() => {
                   console.log(`ID: ${image.id} ${currentUser?.email}`)
                  deleteUpload(image.id,currentUser?.email)
                  closeMenu()
                }}
                >
                  <Typography>{t("restore")}</Typography>
                </Button>
               
              </>
            )}
            
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default connect(
  state => {
    const currentUser = state.entities.users[state.session.id]
    return {
      currentUser,
      images: state?.entities?.uploadedImages || []
    }
  },
  dispatch => ({
    fetchUserUploads: email => dispatch(fetchUserUploads(email)),
    receiveUpload: payload => dispatch(receiveUpload(payload)),
    updateUpload: (image, email) => dispatch(updateUpload(image, email)),
    deleteUpload: (imageId, email) => dispatch(deleteUpload(imageId, email)),
  })
)(Uploads)