// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".imageShow-module__content--2xjQ1 {\n  display: flex;\n}\n\n.imageShow-module__preview--2pLXE {\n  flex-grow: 1;\n}\n\n.imageShow-module__image--k3OpF {\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.imageShow-module__title--1tNGq {\n  text-transform: capitalize;\n}\n\n.imageShow-module__info--Q4lUM {\n  min-width: 320px;\n  margin-left: 32px;\n  display: flex;\n  flex-direction: column;\n}\n\n.imageShow-module__profile--26ezi {\n  display: flex;\n  align-items: center;\n}\n\n.imageShow-module__profileImg--2Codb {\n  width: 40px;\n  height: 40px;\n  background-size: cover;\n  border-radius: 50%;\n}\n\n.imageShow-module__info--Q4lUM > * {\n  margin-bottom: 32px;\n}\n\n.imageShow-module__info--Q4lUM p {\n  margin-left: 8px;\n  font-size: 1.4rem;\n  color: #888;\n}\n\n.imageShow-module__name--Ep8rk {\n  color: black;\n}\n\n.imageShow-module__stretch--3t-M6 {\n  display: flex;\n  flex-direction: column;\n}\n", ""]);
// Exports
exports.locals = {
	"content": "imageShow-module__content--2xjQ1",
	"preview": "imageShow-module__preview--2pLXE",
	"image": "imageShow-module__image--k3OpF",
	"title": "imageShow-module__title--1tNGq",
	"info": "imageShow-module__info--Q4lUM",
	"profile": "imageShow-module__profile--26ezi",
	"profileImg": "imageShow-module__profileImg--2Codb",
	"name": "imageShow-module__name--Ep8rk",
	"stretch": "imageShow-module__stretch--3t-M6"
};
module.exports = exports;
