// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadCard-module__card--3iZLB {\n    min-height: 170px !important;\n    width: 200px !important;\n    position: relative;\n    border: 1px solid #d1d1d1 !important;\n    border-radius: 8px !important;\n    box-shadow: none !important;\n    margin-right: 12px;\n    margin-bottom: 10px;\n  }\n  .UploadCard-module__card--3iZLB p {\n    margin-bottom: 12;\n    font-size: 14px;\n    font-weight: bold;\n  }\n  .UploadCard-module__card--3iZLB p:last-child {\n    margin-bottom: 0 !important;\n    font-size: 11px;\n    font-weight: bold;\n  }\n  .UploadCard-module__card--3iZLB img {\n    min-height: 170px;\n    max-width: 100%;\n    object-fit: cover;\n  }\n  .UploadCard-module__cardInfo--H86_d {\n    padding: 10px 15px;\n  }\n  .UploadCard-module__cardInner--3G0JO {\n    padding: 0 !important;\n  }\n  .UploadCard-module__card--3iZLB button {\n    position: absolute;\n    top: 12px;\n    right: 12px;\n    height: 28px;\n    padding: 0;\n    min-width: 0;\n    width: 28px;\n    border-radius: 50%;\n    z-index: 10;\n    background-color: #fff;\n  }", ""]);
// Exports
exports.locals = {
	"card": "UploadCard-module__card--3iZLB",
	"cardInfo": "UploadCard-module__cardInfo--H86_d",
	"cardInner": "UploadCard-module__cardInner--3G0JO"
};
module.exports = exports;
