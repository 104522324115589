import React from 'react'
import styles from './TextDrawer.module.css'
import scrollbar from '../scrollbar.module.css'
import { useDrag } from 'react-dnd'

function TextItem({ addElement, text, content }) {
  let Canvas
  let style

  switch (content.id) {
    case 0:
      style = styles.heading
      break
    case 1:
      style = styles.subheading
      break
    case 2:
      style = styles.body
      break
    default:
      break
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'text',
    item: content,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const getTextWidth = (text, font) => {
    const canvas = Canvas || (Canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  const AddElement = ({ text, fontWeight, fontSize, fontFamily }) => {
    const element = {
      elementableType: 'Text',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      // _destroy: true
      elementableAttributes: {
        color: '#000000',
        text,
        fontFamily,
        fontWeight,
        fontSize,
        height: fontSize,
        width: getTextWidth(text, `${fontWeight} ${fontSize}px ${fontFamily}`) + 10
      }
    }
    addElement(element)
  }

  return (
    <div className={`${styles.highlightItem} ${style}`} onClick={() => AddElement(content)} ref={drag}>
      <p>{text}</p>
    </div>
  )
}

export default TextItem
