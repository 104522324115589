// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AskMorePopup-module__AskMoreModel--34Tcf {\n  padding: 45px 50px;\n  text-align: center;\n  max-width: 500px;\n  width: 100%;\n  box-sizing: border-box;\n}\n.AskMorePopup-module__AskMoreModelInner--1-yhu {\n  max-width: 300px;\n  width: 300px;\n  overflow: hidden;\n}\n.AskMorePopup-module__AskMoreModelTitle--ibwB_ {\n  font-size: 20px !important;\n  font-weight: bold !important;\n  margin-bottom: 22px !important;\n}\n.AskMorePopup-module__AskMoreModelInput--138K6 {\n  width: 100%;\n  border: 1px solid #d8dee5 !important;\n  border-radius: 10px;\n  font-size: 12px !important;\n  padding: 10px 15px !important;\n  text-align: left;\n  margin-bottom: 15px !important;\n}\n\n.AskMorePopup-module__AskMoreModelInput--138K6 input {\n  font-size: 12px !important;\n  padding: 0px !important;\n  text-align: left;\n}\n.AskMorePopup-module__bluebtn--1zDfq {\n  border: 1px solid #3f51b5 !important;\n  background-color: #3f51b5 !important;\n  color: #fff !important;\n  text-transform: capitalize !important;\n  font-size: 12px !important;\n  line-height: 16px !important;\n  height: auto;\n  border-radius: 50px !important;\n  padding: 5px 18px !important;\n  width: 110px;\n}\n\n@media only screen and (max-width: 768px) {\n  .AskMorePopup-module__AskMoreModel--34Tcf {\n    padding: 40px 20px;\n  }\n  .AskMorePopup-module__AskMoreModelInner--1-yhu {\n    max-width: 262px;\n    width: 262px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"AskMoreModel": "AskMorePopup-module__AskMoreModel--34Tcf",
	"AskMoreModelInner": "AskMorePopup-module__AskMoreModelInner--1-yhu",
	"AskMoreModelTitle": "AskMorePopup-module__AskMoreModelTitle--ibwB_",
	"AskMoreModelInput": "AskMorePopup-module__AskMoreModelInput--138K6",
	"bluebtn": "AskMorePopup-module__bluebtn--1zDfq"
};
module.exports = exports;
