import React from 'react'
import { Grid } from '@material-ui/core'
import StatsItem from './StatsItem'
import { useTranslation } from 'react-i18next'

function Stats({ stats }) {
  const { t } = useTranslation()
  return (
    <Grid container className="userDashboardAction">
      {stats.map(stat => (
        <Grid item key={stat.name} sm={6} md={4} lg={2}>
          <StatsItem name={t(stat.name)} value={stat.value} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Stats
