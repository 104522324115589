// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BrowseIndex-module__container--2tGC5 {\n  flex-grow: 1;\n  display: flex;\n  justify-content: stretch;\n  height: 100%;\n  overflow: hidden;\n}\n\n.BrowseIndex-module__sideBar--3h0pc {\n  background: #f5f5f5;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n.BrowseIndex-module__bar--ik2ta {\n  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);\n  width: 300px;\n\n  font-size: 20px;\n  line-height: 24px;\n}\n\n.BrowseIndex-module__barTitle--1GNFF {\n  display: flex;\n  padding: 20px 20px 0;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.BrowseIndex-module__barTitle--1GNFF h3 {\n  font-size: 25px;\n  font-weight: 500;\n  height: 5rem;\n}\n\n.BrowseIndex-module__bar--ik2ta ul {\n  padding: 0 15px;\n}\n\n.BrowseIndex-module__bar--ik2ta li {\n  border-radius: 10px;\n  background: #f5f5f5;\n  margin: 10px 0;\n  padding: 10px;\n  display: flex;\n  justify-content: flex-start;\n}\n\n.BrowseIndex-module__bar--ik2ta svg {\n  height: 25px;\n  margin-right: 10px;\n}\n\n.BrowseIndex-module__bar--ik2ta button {\n  height: auto;\n  padding: 0;\n}\n\n.BrowseIndex-module__list--MnPOI {\n  padding-top: 32px;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n\n.BrowseIndex-module__listItem--2-Z2o > * {\n  width: 100%;\n  justify-content: flex-start;\n}\n\n.BrowseIndex-module__listItem--2-Z2o ~ .BrowseIndex-module__listItem--2-Z2o {\n  margin-top: 8px;\n}\n\n.BrowseIndex-module__main--3bnri {\n  overflow-y: auto;\n  height: 100%;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "BrowseIndex-module__container--2tGC5",
	"sideBar": "BrowseIndex-module__sideBar--3h0pc",
	"bar": "BrowseIndex-module__bar--ik2ta",
	"barTitle": "BrowseIndex-module__barTitle--1GNFF",
	"list": "BrowseIndex-module__list--MnPOI",
	"listItem": "BrowseIndex-module__listItem--2-Z2o",
	"main": "BrowseIndex-module__main--3bnri"
};
module.exports = exports;
