// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextDrawer-module__textDrawer--32AeC {\n  width: 312px;\n  display: flex;\n  flex-direction: column;\n  padding-left: 16px;\n  padding-right: 24px;\n  color: #fff;\n}\n\n.TextDrawer-module__textDrawer--32AeC > * {\n  margin-bottom: 8px;\n}\n\n.TextDrawer-module__itemList--25GWt {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.TextDrawer-module__item--3wzOH {\n  background: hsla(0, 0%, 100%, 0.07);\n  border-radius: 4px;\n  width: 152px;\n  height: 152px;\n  margin-bottom: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: background-color 0.3s;\n  cursor: pointer;\n  text-align: center;\n}\n\n.TextDrawer-module__item--3wzOH:hover {\n  background: hsla(0, 0%, 100%, 0.14);\n}\n\n/* .item > svg {\n  fill: #c7d0d8;\n  width: 152px;\n  height: 152px;\n} */\n\n.TextDrawer-module__highlights--3pb6m {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n}\n\n.TextDrawer-module__highlightItem--2Dki4 {\n  background: hsla(0, 0%, 100%, 0.07);\n  border-radius: 4px;\n  margin-bottom: 8px;\n  transition: background-color 0.3s;\n  cursor: pointer;\n}\n\n.TextDrawer-module__highlightItem--2Dki4:hover {\n  background: hsla(0, 0%, 100%, 0.14);\n}\n\n.TextDrawer-module__highlightItem--2Dki4 > p {\n  padding: 16px;\n  line-height: 1;\n}\n\n.TextDrawer-module__heading--Gp8Sp {\n  font-weight: 800;\n  font-size: 28px;\n}\n\n.TextDrawer-module__subheading--posMl {\n  font-weight: 400;\n  font-size: 18px;\n}\n\n.TextDrawer-module__body--2Zxyv {\n  font-weight: 300;\n  font-size: 12px;\n}\n\n@media only screen and (max-width: 991px) {\n  .TextDrawer-module__textDrawer--32AeC {\n    width: 240px;\n  }\n  .TextDrawer-module__item--3wzOH {\n    width: 115px;\n    height: 115px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"textDrawer": "TextDrawer-module__textDrawer--32AeC",
	"itemList": "TextDrawer-module__itemList--25GWt",
	"item": "TextDrawer-module__item--3wzOH",
	"highlights": "TextDrawer-module__highlights--3pb6m",
	"highlightItem": "TextDrawer-module__highlightItem--2Dki4",
	"heading": "TextDrawer-module__heading--Gp8Sp",
	"subheading": "TextDrawer-module__subheading--posMl",
	"body": "TextDrawer-module__body--2Zxyv"
};
module.exports = exports;
