// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImageItem-module__more--1Ftma {\n  position: absolute;\n  display: none;\n  top: 4px;\n  right: 4px;\n  color: white;\n  background-color: rgba(0, 0, 0, 0.6);\n  height: 16px;\n  border-radius: 8px;\n  min-width: 32px;\n  border-color: transparent;\n}\n\n.ImageItem-module__more--1Ftma:hover {\n  background-color: #dc85ff;\n  border-color: transparent;\n}\n\n.ImageItem-module__item--3YvQv {\n  position: relative;\n}\n\n.ImageItem-module__item--3YvQv:hover .ImageItem-module__more--1Ftma {\n  display: flex;\n}\n\n.ImageItem-module__more--1Ftma svg {\n  font-size: 1.6rem;\n}\n\n.ImageItem-module__image--QljQz {\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"more": "ImageItem-module__more--1Ftma",
	"item": "ImageItem-module__item--3YvQv",
	"image": "ImageItem-module__image--QljQz"
};
module.exports = exports;
