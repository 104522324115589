import React from 'react'
import styles from './UploadsDrawer.module.css'
import { useDrag } from 'react-dnd'

function CustomImage({ addElement, image }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'custom_image',
    item: image,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  return (
    <div className={styles.item} ref={drag}>
      <img src={image.url} alt="" onClick={() => addElement(image)} className={styles.image} />
    </div>
  )
}

export default CustomImage
