import * as React from 'react'
import SVG from 'react-inlinesvg'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { MenuItem, ListItemText } from '@material-ui/core'
import TopBar from './TopBar'
import { Brands, PlusIcon2 } from '../../../assets/svg'
import AddBrand from './addBrand'
import { receiveAllBrands, deleteBrandById } from '../../../actions/brands_actions'
import { DeleteIcon, RenameIcon } from '../../../assets/svg'
import { useTranslation } from 'react-i18next'
import styles from './index.module.css'

function BrandsPage(props) {
  const [opened, setOpened] = useState(false)
  const [brand, setBrand] = useState({})
  const { brands, removeBrand } = props
  const { t } = useTranslation()

  useEffect(() => {
    const { requestBrands, currentUser } = props
    requestBrands(currentUser.BotId, currentUser.id)
  }, [])

  const handleEdit = item => {
    setBrand(item)
    setOpened(true)
  }

  return (
    <>
      <div className={styles.container}>
        <TopBar title={t('brands')} />
        {brands.length === 0 ? (
          <NoBrands setOpened={setOpened} />
        ) : (
          <>
            <div className={styles.masonry}>
              {brands.map(brand => {
                return (
                  <>
                    <div className={styles.brandCard}>
                      <div className={styles.brandName}>{brand.name ? brand.name : 'Unnamed brand'}</div>
                      <div className={styles.brandLogo}>
                        {brand.images && brand.images.length > 0 && (
                          <img
                            src={brand.images ? brand.images.imgSrc : null}
                            alt={brand.name ? brand.name : 'Unnamed brand'}
                          />
                        )}

                        {!brand.images && 'No logo'}
                      </div>
                      <div className={styles.brandFonts}>
                        Fonts:
                        <div className={styles.brandFont}>
                          <h2
                            style={{
                              fontFamily: brand.font1.family,
                              fontSize: brand.font1.fontSize,
                              fontWeight: brand.font1.fontWeight,
                              fontStyle: brand.font1.fontStyle,
                              textTransform: brand.font1.textTransform,
                              textDecoration: brand.font1.textDecoration
                            }}
                          >
                            Header
                          </h2>
                          <h3
                            style={{
                              fontFamily: brand.font2.family,
                              fontSize: brand.font2.fontSize,
                              fontWeight: brand.font2.fontWeight,
                              fontStyle: brand.font2.fontStyle,
                              textTransform: brand.font2.textTransform,
                              textDecoration: brand.font2.textDecoration
                            }}
                          >
                            Subheader
                          </h3>
                          <p
                            style={{
                              fontFamily: brand.font3.family,
                              fontSize: brand.font3.fontSize,
                              fontWeight: brand.font3.fontWeight,
                              fontStyle: brand.font3.fontStyle,
                              textTransform: brand.font3.textTransform,
                              textDecoration: brand.font3.textDecoration
                            }}
                          >
                            Paragraph
                          </p>
                        </div>
                      </div>
                      {brand.colors && (
                        <div className={styles.brandColor}>
                          Color:
                          <div style={{ backgroundColor: brand.colors }}></div>
                        </div>
                      )}

                      <div className={styles.brandNavigation}>
                        <div className={styles.brandNavigationBar}></div>
                        <div className={styles.brandNavigationMenu}>
                          <div className={styles.brandNavigationMenuBody}>
                            <MenuItem onClick={() => handleEdit(brand)}>
                              <SVG src={RenameIcon} style={{ width: '18px', height: '18px' }} />
                              <ListItemText style={{ marginLeft: '1em' }}>Edit</ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                removeBrand(brand.BotId, brand.id)
                              }}
                            >
                              <SVG src={DeleteIcon} style={{ width: '18px', height: '18px' }} />
                              <ListItemText style={{ marginLeft: '1em' }}>Delete</ListItemText>
                            </MenuItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
              <div className={styles.buttomBar}>
                <button onClick={() => setOpened(true)}>
                  <SVG src={PlusIcon2} style={{ width: '60px', height: 'auto' }} />
                </button>
              </div>
            </div>
          </>
        )}

        {opened ? <AddBrand openPopup={opened} setOpenedProps={setOpened} brand={brand}></AddBrand> : null}
      </div>
    </>
  )
}
function NoBrands(props) {
  const { t } = useTranslation()
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.empty}>
        <SVG src={Brands} />
        <h2>{t('noBrandsYet')}</h2>
        <span>{t('addYourFirstBrand')}</span>
        <button role="button" onClick={() => props.setOpened(true)}>
          {t('addBrand')}
        </button>
      </div>
    </div>
  )
}
export default connect(
  state => {
    const brands = Object.values(state.entities.brands)
    return {
      currentUser: state.entities.users[state.session.id],
      brands: brands
    }
  },
  dispatch => ({
    requestBrands: (botId, clientId) => dispatch(receiveAllBrands(botId, clientId)),
    removeBrand: (botId, id) => dispatch(deleteBrandById(botId, id))
  })
)(BrandsPage)
