import React from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'
import styles from './ImageItem.module.css'
import { useDrag } from 'react-dnd'

function ImageItem({ thumb, id, toggleModal, addElement, image }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'image',
    item: image,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  return (
    <div className={styles.item} ref={drag}>
      <button type="button" className={styles.more} onClick={() => toggleModal(id)}>
        <FiMoreHorizontal />
      </button>
      <img src={thumb} className={styles.image} onClick={() => addElement(image)} alt="" />
    </div>
  )
}

export default ImageItem
