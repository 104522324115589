import React, { useState, useEffect } from 'react'
import i18next from 'i18next'
import En from '../../assets/En.png'
import Ru from '../../assets/Ru.jpg'
import He from '../../assets/He.png'
import Ar from '../../assets/Ar.jpg'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { useTranslation } from 'react-i18next'
import './language.css'

const LanguageSelect = () => {
  const languageMap = {
    en: { label: 'English', dir: 'ltr', active: true, img: En },
    ru: { label: 'русский', dir: 'ltr', active: false, img: Ru },
    he: { label: 'עִברִית', dir: 'rtl', active: false, img: He },
    ar: { label: 'العربية', dir: 'rtl', active: false, img: Ar }
  }
  const selected = localStorage.getItem('i18nextLng') || 'en'

  useEffect(() => {}, [])
  const [menuAnchor, setMenuAnchor] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    document.body.dir = languageMap[selected]?.dir // eslint-disable-next-line
  }, [menuAnchor, selected])

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {selected === 'en-US' ? languageMap['en']?.label : languageMap[selected]?.label}

        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div>
          <List>
            <ListSubheader>{t('select_language')}</ListSubheader>
            {Object.keys(languageMap)?.map(item => (
              <ListItem
                button
                key={item}
                className="lanList"
                onClick={() => {
                  i18next.changeLanguage(item)
                  setMenuAnchor(null)
                }}
              >
                <span className="lanImg">
                  <img src={languageMap[item].img} alt="" height={50} width={50} />
                </span>
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  )
}
export default LanguageSelect
