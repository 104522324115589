// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LayerBtn-module__layersBtn--1X4Nc {\n  position: absolute;\n  bottom: 25px;\n  right: 25px;\n  padding: 14px;\n  border-radius: 8px;\n  width: 48px;\n  height: 48px;\n  background-color: #ffffff;\n  box-shadow: 0 2px 8px rgba(14, 19, 24, 0.15);\n  box-sizing: border-box;\n  z-index: 9;\n  cursor: pointer;\n  transition: 0.3s;\n}\n\n.LayerBtn-module__layersBtn--1X4Nc:not(.LayerBtn-module__disabled--2afOK):hover {\n  background-color: #e2e2e2;\n}\n\n.LayerBtn-module__layersBtn--1X4Nc.LayerBtn-module__disabled--2afOK {\n  opacity: 0.4;\n  cursor: not-allowed;\n}\n\n.LayerBtn-module__layersBtn--1X4Nc.LayerBtn-module__active--2pOEp {\n  background-color: #e2e2e2;\n}\n", ""]);
// Exports
exports.locals = {
	"layersBtn": "LayerBtn-module__layersBtn--1X4Nc",
	"disabled": "LayerBtn-module__disabled--2afOK",
	"active": "LayerBtn-module__active--2pOEp"
};
module.exports = exports;
