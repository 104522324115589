import React, { useState } from 'react'

import { Card, CardContent, Typography, Button, Popover } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'

import styles from './UploadCard.module.css'

function UploadCard({ image, render }) {
    
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
  
    const handleClose = () => {
      setAnchorEl(null)
    }

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardInner}>
        <img
          src={image.url}
          alt=""
        />
        <Button onClick={handleClick}>
          <MoreHoriz />
        </Button>
        <Popover
          id={image.id}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          {render(handleClose)}
        </Popover>
    </CardContent>
      
    </Card>
  )
}

export default UploadCard
