import React, { Fragment } from 'react'

import { Typography, Button } from '@material-ui/core'

import NotificationItem from './NotificationItem'

import styles from './Settings.module.css'
import { useTranslation } from 'react-i18next'
const notifications = [
  {
    id: '1',
    title: 'subject1',
    description: 'thisIsTheSubject1',
    enabled: true
  },
  {
    id: '2',
    title: 'subject2',
    description: 'thisIsTheDescriptionOfSubject2',
    enabled: false
  },
  {
    id: '3',
    title: 'subject3',
    description: 'thisIsTheDescriptionOfSubject3',
    enabled: false
  }
]

function Notification() {
  const { t } = useTranslation()
  return (
    <div className="Settingstemplate">
      {/* header section */}
      <Typography className={styles.pageHeading}>Notification and Email</Typography>
      <Typography className={styles.pageSubheading}>{t('PeopleVisiting')}</Typography>

      {/* notifications section */}
      {notifications.map(({ id, title, description, enabled }) => (
        <Fragment key={id}>
          <NotificationItem title={t(title)} description={t(description)} enabled={enabled} />
        </Fragment>
      ))}

      <Button variant="contained" className="blueBtn">
        {t('save')}
      </Button>
    </div>
  )
}

export default Notification
