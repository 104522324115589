import React, { useState } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'
import TemplateItem from './TemplateItem'
import TemplatesPopup from '../../templates_popup/TemplatesPopup'
import TemplateInfoPopup from './TemplateInfoPopup'
import TemplateSellFinalPopup from './TemplateSellFinalPopup'
import { useTranslation } from 'react-i18next'

const templates = [
  { id: '1', name: 'templates', category: 'food', isForSale: true },
  { id: '2', name: 'templates', category: 'food', isForSale: false },
  { id: '3', name: 'templates', category: 'food', isForSale: true },
  { id: '4', name: 'templates', category: 'food', isForSale: true },
  { id: '5', name: 'templates', category: 'food', isForSale: false },
  { id: '6', name: 'templates', category: 'food', isForSale: false }
]

function Templates() {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [isTemplateInfoOpen, setIsTemplateInfoOpen] = useState(false)
  const [isFinalPopupOpen, setIsFinalPopupOpen] = useState(false)
  const { t } = useTranslation()
  const templatePopupCloseCallback = () => {
    setIsPopupOpen(false)
    setSelectedTemplates([])
  }

  const handleTemplateSelected = template => {
    setSelectedTemplates([template])
  }

  const templateInfoCloseCallback = isPublished => {
    setIsTemplateInfoOpen(false)
    isPublished && templatePopupCloseCallback()
  }

  const templateInfoPublishedCallback = () => {
    setIsFinalPopupOpen(true)
    templateInfoCloseCallback(true)
  }

  const finalPopupCallback = () => {
    setIsFinalPopupOpen(false)
  }

  const actionButtons = [
    <Button onClick={templatePopupCloseCallback}>{t('backToEdit')}</Button>,
    <Button onClick={() => setIsTemplateInfoOpen(true)} disabled={!selectedTemplates.length}>
      {t('next')}
    </Button>
  ]

  return (
    <>
      <Grid container justifyContent="space-between" className="UserTemaplateHeader">
        <Grid item>
          <Typography className="UserTemaplateHeader__title">{t('mydesigns')}</Typography>
        </Grid>
        <Grid item className="UserTemaplateHeader__btns">
          <Button variant="contained" onClick={() => setIsPopupOpen(true)}>
            {t('sellDesigns')}
          </Button>
          <Button variant="outlined"> {t('viewAll')}</Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start">
        {templates.map(({ id, name, category, isForSale }) => (
          <Grid item key={id}>
            <TemplateItem name={t(name)} category={t(category)} isForSale={isForSale} />
          </Grid>
        ))}
      </Grid>
      <TemplatesPopup
        title={t('sellTemplates')}
        subTitle={t('pickonetemplatestosell')}
        templates={templates}
        selectedTemplates={selectedTemplates}
        handleTemplateSelected={handleTemplateSelected}
        isOpen={isPopupOpen}
        callback={templatePopupCloseCallback}
        actionButtons={actionButtons}
      />
      <TemplateInfoPopup
        isOpen={isTemplateInfoOpen}
        callbackClose={templateInfoCloseCallback}
        callbackPublished={templateInfoPublishedCallback}
      />
      <TemplateSellFinalPopup isOpen={isFinalPopupOpen} callback={finalPopupCallback} />
    </>
  )
}

export default Templates
