// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PurchasedTemplates-module__tamplateHeader--UuWa_ {\n  padding: 20px;\n}\n.PurchasedTemplates-module__pageName--QafV3 {\n  font-size: 14px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n}\n.PurchasedTemplates-module__pageBtn--8xq6O {\n  font-size: 12px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n  text-transform: capitalize !important;\n  height: auto !important;\n  padding: 0 !important;\n  display: flex;\n  align-items: center;\n}\n.PurchasedTemplates-module__pageBtn--8xq6O svg {\n  margin-left: 7px;\n  height: 18px;\n  width: 25px;\n}\n.PurchasedTemplates-module__AllCardsBlock--3cYL3 {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 10px;\n  padding: 0 20px 20px;\n}\n.PurchasedTemplates-module__cardSetList--2I8JT {\n  flex-direction: column;\n  align-items: flex-start;\n  display: flex;\n  padding: 6px 0;\n}\n.PurchasedTemplates-module__cardSetList--2I8JT button {\n  text-transform: capitalize !important;\n  font-size: 12px;\n  line-height: 20px;\n  padding: 5px 20px;\n  height: auto;\n  min-height: 0;\n  width: 100%;\n  min-height: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.PurchasedTemplates-module__cardSetList--2I8JT button p {\n  font-size: 12px !important;\n  line-height: 20px !important;\n}\n.PurchasedTemplates-module__cardSetList--2I8JT button svg {\n  margin-right: 8px;\n  width: 15px;\n  height: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"tamplateHeader": "PurchasedTemplates-module__tamplateHeader--UuWa_",
	"pageName": "PurchasedTemplates-module__pageName--QafV3",
	"pageBtn": "PurchasedTemplates-module__pageBtn--8xq6O",
	"AllCardsBlock": "PurchasedTemplates-module__AllCardsBlock--3cYL3",
	"cardSetList": "PurchasedTemplates-module__cardSetList--2I8JT"
};
module.exports = exports;
