// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BrandsKitDrawer-module__templateInner--H0DMz {\n  padding: 15px;\n}\n.BrandsKitDrawer-module__templateInnerTitle--3beBr {\n  font-size: 16px !important;\n  line-height: 22px !important;\n  color: #fff;\n  width: 100%;\n  margin-bottom: 15px !important;\n}\n.BrandsKitDrawer-module__grayCard--3cD5x {\n  background-color: #ffffff11;\n  padding: 15px;\n  border-radius: 10px;\n  margin-bottom: 15px;\n}\n.BrandsKitDrawer-module__grayCardTitle--15z4Z {\n  font-size: 13px !important;\n  line-height: 20px !important;\n  color: #fff;\n  width: 100%;\n  margin-bottom: 8px !important;\n}\n.BrandsKitDrawer-module__cardRow--jXbZx {\n  display: flex;\n  align-items: center;\n}\n.BrandsKitDrawer-module__cardRow--jXbZx button {\n  padding: 0;\n  height: auto;\n}\n.BrandsKitDrawer-module__whiteBtn--aJRxA {\n  background-color: #fff;\n  border-radius: 100px;\n  border: 1px solid #fff;\n  color: #000;\n  font-size: 12px;\n  line-height: 18px;\n  padding: 3px 12px !important;\n  text-transform: capitalize;\n  height: auto !important;\n  margin-left: 12px;\n}\n.BrandsKitDrawer-module__textsizeTitle--3-fEz {\n  color: #fff;\n  width: 200px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin-bottom: 2px !important;\n}\n.BrandsKitDrawer-module__textsize--3Kezl {\n  color: #fff;\n  margin-bottom: 2px !important;\n}\n.BrandsKitDrawer-module__iconBtn--2cK4t {\n  padding: 0;\n  height: auto;\n}\n@media only screen and (max-width: 991px) {\n  .BrandsKitDrawer-module__textsizeTitle--3-fEz {\n    color: #fff;\n    width: 130px;\n  }\n}\n[dir='rtl'] .BrandsKitDrawer-module__whiteBtn--aJRxA {\n  margin-right: 12px;\n  margin-left: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"templateInner": "BrandsKitDrawer-module__templateInner--H0DMz",
	"templateInnerTitle": "BrandsKitDrawer-module__templateInnerTitle--3beBr",
	"grayCard": "BrandsKitDrawer-module__grayCard--3cD5x",
	"grayCardTitle": "BrandsKitDrawer-module__grayCardTitle--15z4Z",
	"cardRow": "BrandsKitDrawer-module__cardRow--jXbZx",
	"whiteBtn": "BrandsKitDrawer-module__whiteBtn--aJRxA",
	"textsizeTitle": "BrandsKitDrawer-module__textsizeTitle--3-fEz",
	"textsize": "BrandsKitDrawer-module__textsize--3Kezl",
	"iconBtn": "BrandsKitDrawer-module__iconBtn--2cK4t"
};
module.exports = exports;
