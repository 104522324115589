import React from 'react'
import SVG from 'react-inlinesvg'
import { getShapes } from '../../../utils/shapes.constants'
import styles from './ElementsDrawer.module.css'
import scrollbar from '../scrollbar.module.css'
import { useState, useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'

function Shape({ item, key, addElement, image }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'shape',
    item: item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  const add_Element = shape => {
    console.log(`Shape: ${JSON.stringify(shape)}`)
    const element = {
      elementableType: 'Shape',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      // _destroy: true
      elementableAttributes: {
        shape,
        color: '#c7d0d8',
        width: 100,
        height: 100
      }
    }

    addElement(element)
  }

  return (
    <div ref={drag} key={key} className={styles.item} onClick={() => add_Element(item.shape)}>
      <SVG src={image} />
    </div>
  )
}

export default Shape
