// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DrawerSearch-module__searchBar--2AMVM {\n  color: #0e1318;\n}\n\n.DrawerSearch-module__searchInput--2hACa {\n  border-radius: 4px;\n  height: 40px;\n  background-color: white;\n  font-size: 2em;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 4px;\n  margin: 24px 32px 8px 16px;\n}\n\n.DrawerSearch-module__searchInput--2hACa svg {\n  width: 40px;\n}\n\n.DrawerSearch-module__inputIcon--8t8gg {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.DrawerSearch-module__input--1OncH {\n  width: 100%;\n  padding-left: 4px;\n  font-size: 1.4rem;\n}\n\n.DrawerSearch-module__hiddenButton--34vVk {\n  display: none;\n}\n\n.DrawerSearch-module__notVisible--1qNiy {\n  visibility: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"searchBar": "DrawerSearch-module__searchBar--2AMVM",
	"searchInput": "DrawerSearch-module__searchInput--2hACa",
	"inputIcon": "DrawerSearch-module__inputIcon--8t8gg",
	"input": "DrawerSearch-module__input--1OncH",
	"hiddenButton": "DrawerSearch-module__hiddenButton--34vVk",
	"notVisible": "DrawerSearch-module__notVisible--1qNiy"
};
module.exports = exports;
