// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__elements--2OlXi {\n  width: 312px;\n  display: flex;\n  flex-direction: column;\n  padding-left: 16px;\n  padding-right: 24px;\n}\n\n.styles-module__itemList--2lHGB {\n  margin-top: 16px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.styles-module__item--2QUSn {\n  width: 100px;\n \n  margin-bottom: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.styles-module__item--2QUSn img{\n max-height: 200px;\n}\n\n.styles-module__item--2QUSn > svg {\n  fill: #c7d0d8;\n  width: 100px;\n  height: 100px;\n}\n\n@media only screen and (max-width: 991px) {\n  .styles-module__elements--2OlXi {\n    width: 240px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"elements": "styles-module__elements--2OlXi",
	"itemList": "styles-module__itemList--2lHGB",
	"item": "styles-module__item--2QUSn"
};
module.exports = exports;
