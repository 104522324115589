import React, { Fragment } from 'react'

import { Grid, Typography, Button, FormGroup, FormControlLabel, Switch, Paper } from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import styles from './Settings.module.css'
import './settings.scss'

import SubsPlanInfoItem from './SubsPlanInfoItem'
import { useTranslation } from 'react-i18next'

const subsPlanInfo = [
  {
    name: 'plan',
    value: 'proPlan'
  },
  {
    name: 'date',
    value: '27/09/2021'
  },
  {
    name: 'untill',
    value: '27/09/2022'
  },
  {
    name: 'renew',
    value: 'yearly'
  }
]

function AccountSettings() {
  const { t } = useTranslation()
  return (
    <div className="Settingstemplate">
      {/* header section */}
      <Typography className={styles.pageHeading}>{t('accountSettings')}</Typography>
      <Typography className={styles.pageSubheading}>{t('accountSettingsDe')}</Typography>

      {/* subscription section */}
      <Typography className="SettingstemplateSubTitle">{t('subscriptionPlan')}</Typography>
      <Paper elevation={1} className="bluecard">
        <Grid container justifyContent="space-between" alignItems="center">
          {subsPlanInfo.map(({ name, value }) => (
            <Fragment key={name}>
              <SubsPlanInfoItem name={t(name)} value={t(value)} />
            </Fragment>
          ))}
          <Button variant="outlined" className="whiteBtn">
            {t('change Plan')}
          </Button>
        </Grid>
        <FormGroup className="Settingstemplatetoggle">
          <FormControlLabel control={<Switch defaultChecked />} label={t('accountRenewal')} />
        </FormGroup>
      </Paper>
      <Typography className="cardTitle"> {t('creditCard')}</Typography>
      <Paper elevation={1} className="graycard">
        <Grid container justifyContent="space-between">
          <Grid item className="smallTitle">
            <Typography>**** **** **** 55622</Typography>
          </Grid>
          <Grid item className="smallTitle">
            <Typography>08/2027</Typography>
          </Grid>
          <Grid item className="smallTitle">
            <Typography>{t('masterCard')}</Typography>
          </Grid>
          <Grid item className="editbtn">
            <Edit />
          </Grid>
        </Grid>
      </Paper>

      {/* language section */}
      <Typography className="SettingstemplateSubTitle">{t('language')}</Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography className="SettingstemplatesmallTitle">{t('selectYourPreferredLanguage')}</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" className="grayBtn">
            {t('english')}
          </Button>
        </Grid>
      </Grid>

      <Button variant="contained" className="blueBtn">
        {t('save')}
      </Button>
    </div>
  )
}

export default AccountSettings
