// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Uploads-module__pageName--2a2JL {\n  font-size: 14px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n}\n.Uploads-module__pageBtn--3kYWC {\n  font-size: 12px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n  text-transform: capitalize !important;\n  height: auto !important;\n  padding: 0 !important;\n  display: flex;\n  align-items: center;\n}\n.Uploads-module__pageBtn--3kYWC svg {\n  margin-left: 7px;\n  height: 18px;\n  width: 25px;\n}\n.Uploads-module__centerBox--1oTeM {\n  text-align: center;\n  padding: 20px;\n}\n.Uploads-module__centerBoxImage--2Q2iR {\n  margin-top: 55px !important;\n  width: 120px;\n  margin: 0 auto;\n}\n.Uploads-module__centerBoxTitle--1on9s {\n  font-size: 24px !important;\n  line-height: 32px !important;\n  color: #000000;\n  margin-bottom: 3px !important;\n  font-weight: 600 !important;\n  margin-top: 20px !important;\n}\n.Uploads-module__centerBoxText--zo3ax {\n  font-size: 13px !important;\n  line-height: 22px !important;\n  color: #585858;\n  margin-bottom: 22px !important;\n}\n.Uploads-module__grayBtn--1XWOP {\n  background-color: #eee !important;\n  -webkit-border-radius: 50px !important;\n  border-radius: 50px !important;\n  padding: 6px 20px !important;\n  min-width: 120px !important;\n  font-weight: 600 !important;\n  font-size: 12px !important;\n  margin: 0 auto;\n  text-transform: capitalize !important;\n  min-height: auto;\n}\n.Uploads-module__uploadTemplates--21Hud {\n  padding: 20px;\n}\n.Uploads-module__uploadsAllCards--eQwWv {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"pageName": "Uploads-module__pageName--2a2JL",
	"pageBtn": "Uploads-module__pageBtn--3kYWC",
	"centerBox": "Uploads-module__centerBox--1oTeM",
	"centerBoxImage": "Uploads-module__centerBoxImage--2Q2iR",
	"centerBoxTitle": "Uploads-module__centerBoxTitle--1on9s",
	"centerBoxText": "Uploads-module__centerBoxText--zo3ax",
	"grayBtn": "Uploads-module__grayBtn--1XWOP",
	"uploadTemplates": "Uploads-module__uploadTemplates--21Hud",
	"uploadsAllCards": "Uploads-module__uploadsAllCards--eQwWv"
};
module.exports = exports;
