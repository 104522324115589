// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Viewer-module__design--2hk40 {\n  background-color: white;\n  position: relative;\n}\n\n.Viewer-module__elementsContainer--1ePEZ {\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"design": "Viewer-module__design--2hk40",
	"elementsContainer": "Viewer-module__elementsContainer--1ePEZ"
};
module.exports = exports;
