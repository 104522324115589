import React from 'react'
import {
  FiLayout,
  FiImage,
  FiGrid,
  FiType,
  FiUploadCloud,
  FiDroplet,
  FiFolder,
  FiMoreHorizontal,
  FiBriefcase
} from 'react-icons/fi'
import styles from './DesignDrawer.module.css'
import { useTranslation } from 'react-i18next'
const ToolsNav = ({ changeDrawer, current, closed, animate }) => {
  const { t } = useTranslation()
  const activeButton = id => (current === id && !closed ? 'active btn-tools' : 'btn-tools')
  return (
    <div className={styles.toolsNav}>
      <div
        className={animate ? styles.highlight : styles.highlightClosed}
        style={{ transform: `translate3d(0px, ${current * 57 + 10}px, 0px)` }}
      >
        <div className={`${styles.background} ${closed ? styles.hidden : ''}`} />
      </div>
      <nav className={styles.buttonsNav}>
        <button type="button" className={activeButton(0)} onClick={() => changeDrawer(0)}>
          <FiLayout />
          <span>{t('templates')}</span>
        </button>
        <button type="button" className={activeButton(1)} onClick={() => changeDrawer(1)}>
          <FiImage />
          <span>{t('photos')}</span>
        </button>
        <button type="button" className={activeButton(2)} onClick={() => changeDrawer(2)}>
          <FiGrid />
          <span>{t('elements')}</span>
        </button>
        <button type="button" className={activeButton(3)} onClick={() => changeDrawer(3)}>
          <FiType />
          <span>{t('text')}</span>
        </button>
        {/* <button type="button" className={activeButton(4)} onClick={() => changeDrawer(4)}>
          <FiDroplet />
          <span>Bkground</span>
        </button> */}
        <button type="button" className={activeButton(4)} onClick={() => changeDrawer(4)}>
          <FiUploadCloud />
          <span>{t('uploads')}</span>
        </button>
        <button type="button" className={activeButton(5)} onClick={() => changeDrawer(5)}>
          <FiBriefcase />
          <span>{t('brandKit')}</span>
        </button>
        {/*<button type="button" className={activeButton(5)} onClick={() => changeDrawer(5)}>*/}
        {/*  <FiFolder />*/}
        {/*  <span>Folders</span>*/}
        {/*</button>*/}
        {/*<button type="button" className={activeButton(6)} onClick={() => changeDrawer(6)}>*/}
        {/*  <FiMoreHorizontal />*/}
        {/*  <span>More</span>*/}
        {/*</button>*/}
      </nav>
    </div>
  )
}

export default ToolsNav
