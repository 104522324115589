// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeletedDesigns-module__pageName--438or {\n  font-size: 14px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n}\n.DeletedDesigns-module__pageBtn--3gR7W {\n  font-size: 12px !important;\n  line-height: 24px !important;\n  color: #000000;\n  margin-bottom: 0 !important;\n  font-weight: 600 !important;\n  text-transform: capitalize !important;\n  height: auto !important;\n  padding: 0 !important;\n  display: flex;\n  align-items: center;\n}\n.DeletedDesigns-module__pageBtn--3gR7W svg {\n  margin-left: 7px;\n  height: 18px;\n  width: 25px;\n}\n.DeletedDesigns-module__centerBox--1oBW6 {\n  text-align: center;\n  padding: 20px;\n}\n.DeletedDesigns-module__centerBoxImage--1dibg {\n  margin-top: 55px !important;\n  width: 120px;\n  margin: 0 auto;\n}\n.DeletedDesigns-module__centerBoxTitle--3TIOv {\n  font-size: 24px !important;\n  line-height: 32px !important;\n  color: #000000;\n  margin-bottom: 3px !important;\n  font-weight: 600 !important;\n  margin-top: 20px !important;\n}\n.DeletedDesigns-module__centerBoxText--24u0g {\n  font-size: 13px !important;\n  line-height: 22px !important;\n  color: #585858;\n  margin-bottom: 22px !important;\n}\n.DeletedDesigns-module__grayBtn--3zREf {\n  background-color: #eee !important;\n  -webkit-border-radius: 50px !important;\n  border-radius: 50px !important;\n  padding: 6px 20px !important;\n  min-width: 120px !important;\n  font-weight: 600 !important;\n  font-size: 12px !important;\n  margin: 0 auto;\n  text-transform: capitalize !important;\n  min-height: auto;\n}\n.DeletedDesigns-module__uploadTemplates--2SeGQ {\n  padding: 20px;\n}\n.DeletedDesigns-module__uploadsAllCards--qnd9i {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"pageName": "DeletedDesigns-module__pageName--438or",
	"pageBtn": "DeletedDesigns-module__pageBtn--3gR7W",
	"centerBox": "DeletedDesigns-module__centerBox--1oBW6",
	"centerBoxImage": "DeletedDesigns-module__centerBoxImage--1dibg",
	"centerBoxTitle": "DeletedDesigns-module__centerBoxTitle--3TIOv",
	"centerBoxText": "DeletedDesigns-module__centerBoxText--24u0g",
	"grayBtn": "DeletedDesigns-module__grayBtn--3zREf",
	"uploadTemplates": "DeletedDesigns-module__uploadTemplates--2SeGQ",
	"uploadsAllCards": "DeletedDesigns-module__uploadsAllCards--qnd9i"
};
module.exports = exports;
