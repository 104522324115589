// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HaveUserNav-module__containerRef--1Z6LT {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.HaveUserNav-module__profileImg--1oYhx {\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n}\n\n.HaveUserNav-module__btnSpan--35CCn {\n  padding: 0 6px;\n}\n", ""]);
// Exports
exports.locals = {
	"containerRef": "HaveUserNav-module__containerRef--1Z6LT",
	"profileImg": "HaveUserNav-module__profileImg--1oYhx",
	"btnSpan": "HaveUserNav-module__btnSpan--35CCn"
};
module.exports = exports;
