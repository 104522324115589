import axios from 'axios'
axios.defaults.withCredentials = true
export const CREATE_BRAND = 'CREATE_BRAND'
export const RECEIVE_BRANDS = 'RECEIVE_BRANDS'
export const RECEIVE_BRAND = 'RECEIVE_BRAND'
export const DELETE_BRAND = 'DELETE_BRAND'
export const EDIT_BRAND = 'EDIT_BRAND'
import { receiveErrors } from './session_actions'

const CreateBrand = brand => ({
  type: CREATE_BRAND,
  payload: brand
})

const EditBrand = brand => ({
  type: EDIT_BRAND,
  payload: brand
})

export const receiveBrands = brands => ({
  type: RECEIVE_BRANDS,
  payload: brands
})
export const receiveBrand = brand => ({
  type: RECEIVE_BRANDS,
  payload: brand
})

export const deleteBrand = brand => ({
  type: DELETE_BRAND,
  payload: brand
})

export const createBrand = brand => dispatch => {
  console.log(`SENT_BRAND_DATA: ${JSON.stringify(brand)}`)
  axios
    .post(`${process.env.REACT_APP_API_URL}/brand/${process.env.REACT_APP_BOT_ID}`, brand, {
      withCredentials: true
    })
    .then(
      payload => {
        console.log(`SENT_BRAND_DATA: ${JSON.stringify(payload)}`)
        dispatch(CreateBrand(payload))
      },
      res => {
        console.log('ERRRR')
        dispatch(receiveErrors(res.responseJSON))
      }
    )
}

export const editBrand = brand => dispatch => {
  console.log(`SENT_BRAND_DATA: ${JSON.stringify(brand)}`)
  axios
    .post(`${process.env.REACT_APP_API_URL}/brand/${process.env.REACT_APP_BOT_ID}/${brand.id}`, brand, {
      withCredentials: true
    })
    .then(
      payload => {
        console.log(`SENT_BRAND_DATA: ${JSON.stringify(payload)}`)
        dispatch(EditBrand(payload))
      },
      res => {
        console.log('ERRRR')
        dispatch(receiveErrors(res.responseJSON))
      }
    )
}

export const receiveAllBrands = (botId, clientId) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/brand/${botId}/client/${clientId}`, {
      withCredentials: true
    })
    .then(
      payload => {
        dispatch(receiveBrands(payload))
      },
      res => {
        dispatch(receiveErrors(res.responseJSON))
      }
    )
}

export const deleteBrandById = (botId, id) => dispatch => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/brand/${botId}/${id}`, {
      withCredentials: true
    })
    .then(
      payload => {
        dispatch(deleteBrand(payload))
      },
      res => {
        dispatch(receiveErrors(res.responseJSON))
      }
    )
}
