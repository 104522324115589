// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserDropDown-module__dropdownCard--Ax2wb {\n  position: absolute;\n  opacity: 0;\n  z-index: 1;\n  transition: opacity 0.3s ease-in-out;\n  right: 0;\n  top: 50px;\n  border-radius: 4px;\n  padding: 8px 0;\n  background-color: white;\n  border: 1px solid rgb(222, 222, 222);\n  box-shadow: 0 2px 12px rgb(222, 222, 222);\n  z-index: 9;\n}\n\n.UserDropDown-module__profile--2M_B2 {\n  display: flex;\n  width: 212px;\n  padding: 8px 16px;\n}\n\n.UserDropDown-module__profileText--1n1P9 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-left: 12px;\n}\n\n.UserDropDown-module__profileText--1n1P9 > small {\n  color: rgb(113, 116, 119);\n}\n\n.UserDropDown-module__profileImg--2YLRs > img {\n  border-radius: 50%;\n  width: 64px;\n  height: 64px;\n}\n\n.UserDropDown-module__hr--1ahYz {\n  border: 0;\n  border-top: 1px solid rgb(222, 222, 222);\n}\n\n.UserDropDown-module__listItem--24WAs {\n  display: flex;\n  align-items: center;\n  height: 40px;\n  padding: 0 16px;\n  color: #303030;\n  cursor: pointer;\n}\n\n.UserDropDown-module__listItem--24WAs:hover {\n  background-color: rgb(238, 238, 238);\n}\n\n.UserDropDown-module__disabled--lWazd {\n  cursor: not-allowed;\n}\n\n.UserDropDown-module__animate--1rYav {\n  opacity: 1;\n}\n\n.UserDropDown-module__gone--UQb-2 {\n  opacity: 0;\n}\n\n.UserDropDown-module__listItem--24WAs > svg {\n  font-size: 2rem;\n}\n\n[dir='rtl'] .UserDropDown-module__dropdownCard--Ax2wb {\n  right: auto;\n  left: 0;\n}\n[dir='rtl'] .UserDropDown-module__profileText--1n1P9 {\n  margin-right: 12px;\n  margin-left: 12px;\n}\n", ""]);
// Exports
exports.locals = {
	"dropdownCard": "UserDropDown-module__dropdownCard--Ax2wb",
	"profile": "UserDropDown-module__profile--2M_B2",
	"profileText": "UserDropDown-module__profileText--1n1P9",
	"profileImg": "UserDropDown-module__profileImg--2YLRs",
	"hr": "UserDropDown-module__hr--1ahYz",
	"listItem": "UserDropDown-module__listItem--24WAs",
	"disabled": "UserDropDown-module__disabled--lWazd",
	"animate": "UserDropDown-module__animate--1rYav",
	"gone": "UserDropDown-module__gone--UQb-2"
};
module.exports = exports;
