// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PricingPlans-module__container--WgRYQ {\n  display: flex;\n  overflow: auto;\n  height: calc(100vh - 66px);\n  align-items: center;\n  justify-content: center;\n  overflow-y: scroll;\n}\n.PricingPlans-module__container_inner--2wAgo {\n  display: flex;\n  width: 70%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 1vw;\n  padding: 110px 0 40px;\n}\n.PricingPlans-module__container_inner--2wAgo h1 {\n  margin: 0% 0 1% 0;\n}\n.PricingPlans-module__bar--2LzEf {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 0 2% 0;\n  width: 100%;\n}\n.PricingPlans-module__bar--2LzEf p {\n  font-size: 1.1vw;\n}\n\n.PricingPlans-module__main_price--VcWmx {\n  font-size: 2.5em;\n  font-weight: bold;\n}\n.PricingPlans-module__secondary_price--2umdn {\n  font-size: 1.2em;\n  text-decoration: line-through;\n  margin-left: 0.8em;\n  color: silver;\n}\n.PricingPlans-module__list_item--25igC {\n  font-size: 1vw;\n}\n\n.PricingPlans-module__chip--3vZd1 {\n  background-color: #3dd598;\n}\n.PricingPlans-module__btn--1oOUd {\n  background-color: #1e75ff;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "PricingPlans-module__container--WgRYQ",
	"container_inner": "PricingPlans-module__container_inner--2wAgo",
	"bar": "PricingPlans-module__bar--2LzEf",
	"main_price": "PricingPlans-module__main_price--VcWmx",
	"secondary_price": "PricingPlans-module__secondary_price--2umdn",
	"list_item": "PricingPlans-module__list_item--25igC",
	"chip": "PricingPlans-module__chip--3vZd1",
	"btn": "PricingPlans-module__btn--1oOUd"
};
module.exports = exports;
