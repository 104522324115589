import React from 'react'
import SVG from 'react-inlinesvg'

import { RingIcon, SearchIcon2 } from '../../assets/svg'

import styles from './styles.scss'

import CustomersTable from './CustomersTable'
import { useTranslation } from 'react-i18next'

function Index(props) {
  const { t } = useTranslation()
  return (
    <div>
      <div className="tableHeader">
        <div>
          <h1>{t('customers')}</h1>
          <span className="subtext">{t('cTitle')}</span>
        </div>
        <div className="rightSide">
          <div className="search">
            <input placeholder={t('search')} />
            <SVG src={SearchIcon2} />
          </div>
          <select>
            <option>{t('lastDays')}</option>
            <option>{t('lastDay')}</option>
            <option>{t('lastHours')}</option>
          </select>
          <button className="iconButton">
            <SVG src={RingIcon} />
          </button>
          <button className="iconButton"></button>
        </div>
      </div>
      <CustomersTable />
    </div>
  )
}

export default Index
