import React from 'react'
import Moveable from 'react-moveable'
import Element from './elements/Element'
import { DimensionView, EditablePanel } from './tools'
import styles from './Design.module.css'

import { findDOMNode } from 'react-dom'
import { DropTarget } from 'react-dnd'

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
    itemCoor: monitor.getClientOffset(),
    dropResult: monitor.getDropResult(),
    endDrag: monitor.didDrop()
  }
}
const designTarget = {
  hover(props, monitor, component) {
    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can use componentDidUpdate() to handle enter/leave.

    // You can access the coordinates if you need them
    const clientOffset = monitor.getClientOffset()
    //console.log(`XY: ${JSON.stringify(clientOffset)}`)

    const componentRect = findDOMNode(component).getBoundingClientRect()
    console.log(`BASE_X: ${clientOffset.x - componentRect.x} Y: ${clientOffset.y - componentRect.y}`)
  },
  drop(props, monitor, component) {
    // if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
    //   return
    // }

    // // Obtain the dragged item
    // const item = monitor.getItem()

    // // You can do something with it
    // ChessActions.movePiece(item.fromPosition, props.position)
    const clientOffset = monitor.getClientOffset()
    const componentRect = findDOMNode(component).getBoundingClientRect()

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    console.log(`compRect: ${JSON.stringify(componentRect)}`)

    return { x: clientOffset.x - componentRect.x, y: clientOffset.y - componentRect.y }
  }
}
// const Shape = () => <div>I am a shape</div>

class Design extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      target: null,
      clippable: false
    }
    this.frame = {
      translate: [0, 0],
      rotate: 0
    }
    this.keepRatio = false
    this.myRef = React.createRef()
    this.holdShift = this.holdShift.bind(this)
    this.releaseShift = this.releaseShift.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.holdShift)
    document.addEventListener('keyup', this.releaseShift)
    const target = document.querySelector('.target')
    this.setState({ target })
    this.element = this.props.elements[0]
  }

  componentDidUpdate(prevProps) {
    const { zoom, selection } = this.props

    if (zoom !== prevProps.zoom) {
      this.myRef.current.updateRect()
    }
    if (prevProps.selection && !selection) {
      this.setState({ target: null })
    }
    const { item, dropResult, itemType } = this.props

    if (prevProps.isOver && !this.props.isOver) {
      if (dropResult) {
        switch (itemType) {
          case 'shape':
            this.addShape(item.shape)
            break
          case 'text':
            this.addText(item)
            break
          case 'image':
            console.log('ADDIMAGE')
            this.addImage(item)
            break
          case 'custom_image':
            this.addCustomImage(item)
            break
          default:
            break
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.holdShift)
    document.removeEventListener('keyup', this.releaseShift)
  }

  holdShift(e) {
    if (e.shiftKey) {
      this.keepRatio = true
    }
  }

  releaseShift() {
    this.keepRatio = false
  }

  // onControlledDragStop(e, element, position) {
  //   const { updateElement, zoom } = this.props;
  //   const { x, y } = position;
  //   updateElement(null, { ...element, posX: x / zoom, posY: y / zoom });
  // }

  select(id) {
    const target = document.getElementById(id)
    const { setSelection, elements } = this.props
    this.setState({ target })
    this.element = elements[id]
    setSelection(this.element.id)
  }

  updateSelected() {
    const { receiveElement } = this.props
    receiveElement(this.element)
  }

  addCustomImage({ width, height, url }) {
    const { addElement, design } = this.props

    const dimensionsHeight = design.height
    const dimensionsWidth = design.width

    if (width > dimensionsWidth) {
      width = dimensionsWidth
    }

    if (height > dimensionsHeight) {
      height = dimensionsHeight
    }
    console.log(`DEsingAFTER: ${height} ${width}`)
    const element = {
      elementableType: 'Image',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      elementableAttributes: {
        url,
        width,
        height
      }
    }
    addElement(element)
  }
  addImage({ width, height, full: url }) {
    //const { dimensionsHeight, dimensionsWidth } = this.props
    const { addElement, design } = this.props

    const dimensionsHeight = design.height
    const dimensionsWidth = design.width

    if (width > dimensionsWidth) {
      width = dimensionsWidth
    }

    if (height > dimensionsHeight) {
      height = dimensionsHeight
    }

    const element = {
      elementableType: 'Image',
      transparency: 1,
      zIndex: 0,
      posX: 0,
      posY: 0,
      elementableAttributes: {
        url,
        width,
        height
      }
    }
    addElement(element)
  }

  addShape(shape) {
    const { addElement, dropResult, design, zoom } = this.props
    const size = Math.min(design.width, design.height) * 0.25

    const element = {
      elementableType: 'Shape',
      transparency: 1,
      zIndex: 0,
      posX: dropResult.x / zoom - size / 2.5,
      posY: dropResult.y / zoom - size / 2.5,
      // _destroy: true
      elementableAttributes: {
        shape,
        color: '#c7d0d8',
        width: size,
        height: size
      }
    }

    addElement(element)
    console.log(`ADDED`)
    //this.updateSelected()
  }

  addText({ text, fontWeight, fontSize, fontFamily }) {
    const getTextWidth = (text, font) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font
      const metrics = context.measureText(text)
      return metrics.width
    }

    const { addElement, dropResult, design, zoom } = this.props
    const size = Math.min(design.width, design.height) * 0.25

    const element = {
      elementableType: 'Text',
      transparency: 1,
      zIndex: 0,
      posX: dropResult.x / zoom - size / 4,
      posY: dropResult.y / zoom - size / 4,
      // _destroy: true
      elementableAttributes: {
        color: '#000000',
        text,
        fontFamily,
        fontWeight,
        fontSize,
        height: fontSize,
        width: getTextWidth(text, `${fontWeight} ${fontSize}px ${fontFamily}`) + 10
      }
    }
    addElement(element)
  }

  render() {
    const { elements, design, zoom } = this.props
    const { target, clippable } = this.state

    const { isOver, item, didDrop, endDrag, canDrop, connectDropTarget, itemCoor } = this.props

    return connectDropTarget(
      <div className={styles.design} style={{ width: design.width * zoom, height: design.height * zoom }}>
        <Moveable
          ref={this.myRef}
          target={target}
          draggable
          throttleDrag={0}
          resizable
          clippable={clippable}
          keepRatio={this.keepRatio}
          throttleResize={0}
          rotatable
          rotationPosition="top"
          throttleRotate={0}
          defaultClipPath={'inset'}
          onDragStart={({ set }) => {
            this.frame.rotate = target.style.transform
              ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0])
              : 0
            this.frame.translate = [parseInt(target.style.left, 10), parseInt(target.style.top, 10)]
            set(this.frame.translate)
          }}
          onDrag={({ beforeTranslate }) => {
            this.frame.translate = beforeTranslate
          }}
          onDragEnd={({ isDrag }) => {
            this.element.posX = this.frame.translate[0] / zoom
            this.element.posY = this.frame.translate[1] / zoom
            if (isDrag) this.updateSelected()
          }}
          onResizeStart={({ setOrigin, dragStart }) => {
            setOrigin(['%', '%'])
            this.frame.rotate = target.style.transform
              ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0])
              : 0
            this.frame.translate = [parseInt(target.style.left, 10), parseInt(target.style.top, 10)]
            dragStart && dragStart.set(this.frame.translate)
          }}
          onResize={({ width, height, drag }) => {
            this.frame.translate = drag.beforeTranslate
            target.style.width = `${width}px`
            target.style.height = `${height}px`
            this.element.elementableAttributes.width = width / zoom
            this.element.elementableAttributes.height = height / zoom

            target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`
            this.setState({})
          }}
          onResizeEnd={({ isDrag }) => {
            if (isDrag) this.updateSelected()
          }}
          onRotateStart={({ set }) => {
            this.frame.translate = [parseInt(target.style.left, 10), parseInt(target.style.top, 10)]
            this.frame.rotate = target.style.transform
              ? parseFloat(target.style.transform.split('rotate(')[1].split('deg)')[0])
              : 0
            set(this.frame.rotate)
          }}
          onRotate={({ beforeRotate }) => {
            this.frame.rotate = beforeRotate
          }}
          onRotateEnd={({ isDrag }) => {
            this.element.rotate = this.frame.rotate
            if (isDrag) this.updateSelected()
          }}
          onRender={() => {
            target.style.left = `${this.frame.translate[0]}px`
            target.style.top = `${this.frame.translate[1]}px`
            target.style.transform = `rotate(${this.frame.rotate}deg)`
          }}
          ables={[DimensionView, EditablePanel]}
          props={{
            dimensionView: true,
            editablePanel: true
          }}
          onClip={e => {
            if (e.clipType === 'rect') {
              target.style.clip = e.clipStyle
            } else {
              target.style.clipPath = e.clipStyle
            }
            this.frame.clipStyle = e.clipStyle
          }}
          onClippableToggle={() => this.setState({ clippable: !clippable })}
        />
        <div className={styles.elementsContainer} id="noElement">
          {elements.map((element, index) => {
            if (element._destroy) return null
            return (
              <div
                key={index}
                id={index}
                style={{
                  position: 'absolute',
                  zIndex: element.zIndex,
                  left: element.posX * zoom,
                  top: element.posY * zoom,
                  transform: `rotate(${element.rotate}deg)`
                  // transform: `translate(${element.posX * zoom}px, ${element.posY * zoom}px)`,
                }}
                onClick={() => this.select(index)}
              >
                <Element element={element} zoom={zoom} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
export default DropTarget(['shape', 'text', 'image', 'custom_image'], designTarget, collect)(Design)
