// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainAuth-module__splash--3lN2- {\n  width: 720px;\n}\n\n.MainAuth-module__main--RBgtp {\n  display: flex;\n  height: 100%;\n}\n\n.MainAuth-module__main--RBgtp > div,\n.MainAuth-module__main--RBgtp > form {\n  flex: 1;\n}\n\n.MainAuth-module__main--RBgtp > div:nth-child(2) {\n  background: rgb(51, 3, 102);\n  background: linear-gradient(145deg, rgba(51, 3, 102, 1) 0%, rgba(185, 26, 84, 1) 100%);\n}\n", ""]);
// Exports
exports.locals = {
	"splash": "MainAuth-module__splash--3lN2-",
	"main": "MainAuth-module__main--RBgtp"
};
module.exports = exports;
