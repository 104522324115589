import React from 'react'
import { Card, CardMedia, CardContent } from '@material-ui/core'

import styles from './Explore.module.css'
import PaidIcon from '@mui/icons-material/Paid'
function TemplateCard({ template, handleDesignSelected }) {
  return (
    <>
      <Card className={styles.designWrapper} onClick={() => handleDesignSelected(template)}>
        <CardContent className={styles.cardInner}>
          <img
            src={encodeURI(
              `${process.env.REACT_APP_MEDIA_URL}/cloud/${process.env.REACT_APP_BOT_ID}/${template?.email}/Canvas/All designs/${template.id}.png`
            )}
            alt={template.title}
          />
          {template?.templatePrice > 0 && (
            <PaidIcon
              style={{ position: 'absolute', right: '25px', top: '155px', color: 'gold' }}
              fontSize="large"
            ></PaidIcon>
          )}
        </CardContent>
      </Card>
      <h4 className={styles.templateSize}>{`${
        template?.sizeCategory.label === 'Custom Dimensions'
          ? `${template?.sizeCategory.label} ${template.width}px X ${template.height}px `
          : `${template?.sizeCategory.label}`
      }`}</h4>
    </>
  )
}

export default TemplateCard
